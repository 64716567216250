import React, {Component} from 'react'
import { Button, Table, Radio, Modal, message, Tooltip, Icon } from 'antd'
import WithdrawForm from '../withdrawForm'
import { Ajax } from '../../util/ajax'
import { Link } from 'react-router-dom'
import './index.less'
import eventBus from '../../eventBus/index'
import withdrawFilter from '../../filter/withdraw'
import refundFilter from '../../filter/refund'
import { parseAmount } from '../../util/util'

export default class ActivityManage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      currentUserId: 0,
      account: {
        amount: '0.00',
        withdrawableBalance: '0.00',
        withdrawAmount: '0.00',
        frozenAmount: '0.00',
        nickname: '',
        avatar: '',
        incomeAmount: '0.00'
      },
      nickname: '',
      avatar: '',
      incomeList: [],
      activityList: [],
      withdrawList:[],
      incomePageNum: 1,
      incomePageSize: 10,
      incomeCount: 0,
      incomeTableLoading: false,
      activityPageNum: 1,
      activityPageSize: 10,
      activityCount: 0,
      activityTableLoading: false,
      withdrawPageNum: 1,
      withdrawPageSize: 10,
      withdrawCount: 0,
      withdrawTableLoading: false,
      visible: false,
      activityTableVisible: true,
      incomeTableVisible: false,
      withdrawTableVisible: false,
      withdrawableBalance: 0,
      withdrawBtnDisable: false,
      refundList: [],
      refundPageNum: 1,
      refundPageSize: 10,
      refundCount: 0,
      refundTableLoading: false,
      refundTableVisible: false,
      activityColumns: [
        {
          title: '活动编号',
          dataIndex: "activity_number",
          width: 120
        }, {
          title: "活动名称",
          dataIndex: 'activity_title',
          width: 150
        }, {
          title: '活动日期',
          width: 360,
          render: (text, record) => {
            return <span>{record.activity_start_time} 至 {record.activity_end_time} </span>
          }
        }, {
          title: '报名人数',
          render: (text, record) => {
            return <span>{record.people} / {record.stock}</span>
          }
        }, {
          title: '订单总额',
          dataIndex: 'price'
        }, {
          title: '订单详情',
          dataIndex: 'opeartion',
          width: 120,
          render: (text, record) => {
            return <Link to={{pathname: '/my/activity/income/detail', state: {activityId: record.activity_id}}}>查看详情</Link>
          }
        }
      ],
      incomeColumns: [
        {
          title: '时间',
          dataIndex: 'applyTime',
        }, {
          title: '类型',
          render: (record) => {
              return <span>活动报名</span>
          }
        }, {
          title: '支付方式&支付单号',
          render: (text, record) => {
            return <span>微信支付-{record.transactionId}</span>
          }
        }, {
          title: '订单金额(元)',
          dataIndex: 'applyPrice',
          render: (text, record) => {
            return <span>￥{parseAmount(record.applyPrice)}</span>
          }
        }, {
          title: '佣金比例',
          dataIndex: 'commissionRate',
          render: (text, record) => {
            return <span>{record.commissionRate}%</span>
          }
        }, {
          title: '实际收入',
          dataIndex: 'income',
          render: (text, record) => {
            return <span>￥{parseAmount(record.income)}</span>
          }
        }
      ],
      withdrawColumns: [
        {
          title: '时间',
          dataIndex: 'createdTime'
        }, {
          title: '金额',
          dataIndex: 'amount',
          render: (text, record) => {
            return <span>￥{parseAmount(record.amount)}</span>
          }
        }, {
          title: '到账账号',
          dataIndex: 'nickname'
        }, {
          title: '状态',
          dataIndex: 'status',
          render: (text, record) => {
            return <span>{withdrawFilter.status[record.status]}</span>
          }
        }
      ],
      refundColumns: [
        {
          title: '活动编号',
          dataIndex: "activityNumber",
          width: 120
        }, {
          title: "活动名称",
          dataIndex: 'activityTitle',
          width: 150
        }, {
          title: '头像',
          dataIndex: 'avatarUrl',
          width: 100,
          render: (record) => {
            return <img src={record} alt="" style={{ width: '45px', height: '47px' }} />
          }
        }, {
          title: '用户',
          dataIndex: 'nickname'
        }, {
          title: '退款金额',
          dataIndex: 'refundFee',
          render: (text, record) => {
            return <span>￥{parseAmount(record.refundFee)}</span>
          }
        }, {
          title: '退款时间',
          dataIndex: 'refundTime'
        }, {
          title: '退款类型',
          dataIndex: 'refundType',
          render: (text, record) => {
            return <span>{refundFilter.refundType[record.refundType]}</span>
          }
        }, {
          title: '退款状态',
          dataIndex: 'refundStatus',
          render: (text, record) => {
            return <span>{refundFilter.refundStatus[record.refundStatus]}</span>
          }
        }
      ]
    }
  }

  componentDidMount() {
    this.getUserAccount()
   //this.getUserIncomes(this.state.incomePageSize,this.state.incomePageNum)
    this.getUserActivities(this.state.activityPageSize, this.state.activityPageNum)
    this.getUserWithdraws(this.state.withdrawPageSize,this.state.withdrawPageNum)
    this.getUserRefunds(this.state.refundPageSize,this.state.refundPageNum)
    eventBus.addListener('withdrawSuccess', () => {
      this.hideModal()
      this.getUserAccount()
      this.getUserWithdraws(this.state.withdrawPageSize, 1)
    })
  }

  getUserAccount() {
    Ajax.POST(`/withdraw/user/account`).then(r => {
      if (r.code === 200) {
        console.log(r.data)
        this.setState({
          account: r.data
        })
        if (r.data.withdrawableBalance < 10) {
          this.setState({
            withdrawBtnDisable: true
          })
        }
      }
    })
  }
  getUserActivities(pageSize, pageNum) {
    this.setState({
      activityTableLoading: true
    })
    let url = `/apply/list/all?pageSize=${pageSize}&pageNum=${pageNum}`
    Ajax.POST(url).then( r =>{
      if(r.code === 200){
        let myData = r.data.list
        let count = r.data.total
        myData.forEach((item, index, arr) => {
            arr[index].key = index
        });
        this.setState({
          activityList: myData,
          activityCount: count,
          activityTableLoading: false
        })
      }else{
        message.error(r.message)
      }
    })
  }
  getUserIncomes(pageSize, pageNum) {
    this.setState({
      incomeTableLoading: true
    })
    Ajax.POST(`/withdraw/list/user/income?pageSize=${pageSize}&pageNum=${pageNum}`).then(r => {
      if (r.code === 200) {
        let myData = r.data.list
        let count = r.data.total
        myData.forEach((item, index, arr) => {
          arr[index].key = index
        });
        this.setState({
          incomeList: myData,
          incomeCount: count,
          incomeTableLoading: false
        })
      } else {
        message.error(r.message)
      }
    })
  }
  getUserWithdraws(pageSize, pageNum) {
    this.setState({
      withdrawTableLoading: true
    })
    Ajax.POST(`/withdraw/list/user?pageSize=${pageSize}&pageNum=${pageNum}`).then(r => {
      if (r.code === 200) {
        let myData = r.data.list
        let count = r.data.total
        myData.forEach((item, index, arr) => {
          arr[index].key = index
        });
        this.setState({
          withdrawList: myData,
          withdrawCount: count,
          withdrawTableLoading: false
        })
      }
    })
  }
  getUserRefunds(pageSize, pageNum) {
    this.setState({
      refundTableLoading: true
    })
    Ajax.POST(`/refund/taker/list?pageSize=${pageSize}&pageNum=${pageNum}`).then(r => {
      if (r.code === 200) {
        let myData = r.data.list
        let count = r.data.total
        myData.forEach((item, index, arr) => {
          arr[index].key = index
        });
        this.setState({
          refundList: myData,
          refundCount: count,
          refundTableLoading: false
        })
      }
    })
  } 
  callback(key) {
    if (key === '1') {
      this.props.history.push({pathname: '/my/activity/manage'})
    } else if (key === '3') {
      this.props.history.push({pathname: '/my/profile/setting'})
    }
  }
  handleChangeRadio(e) {
    console.log(e)
    if (e.target.value === 'income') {
      this.setState({
        activityTableVisible: true,
        withdrawTableVisible: false,
        refundTableVisible: false
      })
    } else if (e.target.value === 'refund') {
      this.setState({
        activityTableVisible: false,
        withdrawTableVisible: false,
        refundTableVisible: true
      })
    } else {
      this.setState({
        activityTableVisible: false,
        refundTableVisible: false,
        withdrawTableVisible: true
      })
    }
  }
  handleWithdraw() {
    this.setState({
      visible: true
    })
  }
  hideModal() {
    this.setState({
      visible: false
    })
    eventBus.emit('hideWithdrawModal')
  }
  render() {
    var self = this
    return (
      <div className="my-finance-manage">
        <div className="my-finance-manage-content">
        <div className="card-title">我的财务</div>
        <div className="finance-data">
          <div className="finance-data-item">
            <div className="item-title">累计订单金额(元)</div>
            <div className="item-data" style={{ color: "#E35F4B" }}>{parseAmount(this.state.account.amount)}</div>
          </div>
          <div className="finance-data-item">
            <div className="item-title">累计收入(元)</div>
            <div className="item-data" style={{ color: "#389E0D" }}>{parseAmount(this.state.account.incomeAmount)}</div>
          </div>
          <div className="finance-data-item">
            <div className="item-title">已提现金额(元)</div>
            <div className="item-data">{parseAmount(this.state.account.withdrawAmount)}</div>
          </div>
          <div className="finance-data-item">
            <div className="item-title">可提现余额(元)<Tooltip title="最低提现金额10元，单日最高提现金额5000元"><Icon type="question-circle" style={{ color: "#E35F4B", marginLeft: 5 }}/></Tooltip></div>
            <div className="item-data withdraw-btn">
              <div style={{ width: 180, textAlign: "center" }}>{parseAmount(this.state.account.withdrawableBalance)}</div>
              <div><Button type="primary" style={{ width: 41,height: 24, fontSize: 12, paddingLeft: 8 }} onClick={this.handleWithdraw.bind(this)} disabled={this.state.withdrawBtnDisable}>提现 </Button></div>
            </div>
          </div>
          {/* <div className="finance-data-item">
            <div className="item-title">冻结金额(元)</div>
            <div className="item-data">{parseAmount(this.state.account.frozenAmount)}</div>
          </div> */}
        </div>
        <div>
        <Radio.Group defaultValue="income" buttonStyle="solid" style= {{ marginTop: 32 }} onChange={this.handleChangeRadio.bind(this)}>
          <Radio.Button value="income">收入记录</Radio.Button>
          <Radio.Button value="refund">退款记录</Radio.Button>
          <Radio.Button value="withdraw">提现记录</Radio.Button>
        </Radio.Group>
        </div>
        <div className="finance-list-table">
          { this.state.activityTableVisible &&
          <Table
            dataSource={this.state.activityList}
            columns={this.state.activityColumns}
            loading={this.state.activityTableLoading}
            pagination={{  //分页
              total: this.state.activityCount, //数据总数量
              pageSize: this.state.activityPageSize,  //显示几条一页
              defaultPageSize: this.state.activityPageSize, //默认显示几条一页
              showSizeChanger: false,  //是否显示可以设置几条一页的选项

              onChange(current) {  //点击改变页数的选项时调用函数，current:将要跳转的页数
                  console.log(current)
                  // this.gotoThispage(current, this.state.queryInfo.pageSize);
                  self.state.activityPageNum = current;
                  self.getUserActivities(self.state.activityPageSize, self.state.activityPageNum)
              },
              showTotal() {  //设置显示一共几条数据
                  return '共 ' + this.total + ' 条数据';
              }
            }}>
          </Table>}
          { this.state.refundTableVisible &&
          <Table
            dataSource={this.state.refundList}
            columns={this.state.refundColumns}
            loading={this.state.refundTableLoading}
            pagination={{  //分页
              total: this.state.refundCount, //数据总数量
              pageSize: this.state.refundPageSize,  //显示几条一页
              defaultPageSize: this.state.refundPageSize, //默认显示几条一页
              showSizeChanger: false,  //是否显示可以设置几条一页的选项

              onChange(current) {  //点击改变页数的选项时调用函数，current:将要跳转的页数
                  console.log(current)
                  // this.gotoThispage(current, this.state.queryInfo.pageSize);
                  self.state.refundPageNum = current;
                  self.getUserRefunds(self.state.refundPageSize, self.state.refundPageNum)
              },
              showTotal() {  //设置显示一共几条数据
                  return '共 ' + this.total + ' 条数据';
              }
            }}>
          </Table>}
          { this.state.withdrawTableVisible &&
          <Table
            dataSource={this.state.withdrawList}
            columns={this.state.withdrawColumns}
            loading={this.state.withdrawTableLoading}
            pagination={{  //分页
              total: this.state.withdrawCount, //数据总数量
              pageSize: this.state.withdrawPageSize,  //显示几条一页
              defaultPageSize: this.state.withdrawPageSize, //默认显示几条一页
              showSizeChanger: false,  //是否显示可以设置几条一页的选项

              onChange(current) {  //点击改变页数的选项时调用函数，current:将要跳转的页数
                  console.log(current)
                  // this.gotoThispage(current, this.state.queryInfo.pageSize);
                  self.state.withdrawPageNum = current;
                  self.getUserWithdraws(self.state.withdrawPageSize, self.state.withdrawPageNum)
              },
              showTotal() {  //设置显示一共几条数据
                  return '共 ' + this.total + ' 条数据';
              }
            }}>
          </Table>}
        </div>
        <Modal
        width="720px"
        visible={this.state.visible}
        footer={null}
        onCancel={this.hideModal.bind(this)}>
          <div style={{ paddingRight: 30 }}>
          <WithdrawForm 
          withdrawableBalance={this.state.account.withdrawableBalance}
          nickname={this.state.account.nickname}
          avatar={this.state.account.avatarUrl}
          userId={this.state.account.userId}
          ></WithdrawForm>
          </div>
        </Modal>
        </div>
      </div>
    )
  }
}
