import React, { Component } from 'react';
import {Table, Input, message, Button, Breadcrumb} from 'antd';
import { Ajax } from '../../util/ajax'
import { Link } from 'react-router-dom'
import { IconFont } from '../../components/IconFont'
import './index.less'
const Search = Input.Search;

export default class EvaluateManage extends Component {
  constructor(props){
    super(props)
    this.state = {
      myData: [],
      tabLoading: false,
      condition:"",
      pageSize: 10,
      pageNum: 1,
      columns : [
        {
          title: '活动编号',
          dataIndex: 'activityNumber',
        }, {
          title: '活动标题',
          dataIndex: 'activityTitle',
          width: 120
        }, {
          title: '评价人',
          dataIndex: 'nickname'
        },{
          title: '图片',
          dataIndex: 'imgs',
          width: 80,
          render: (record) => {
            return <img src={record[0]} alt="" style={{ width: '53.63px', height: '36px' }} />
          }
        },{
          title: '星',
          dataIndex: 'evaluateStar',
          render: (text,record)=>{
            var items = [];
            for (var i = 0; i < 5; i++) {
              items.push(<IconFont key={i} style={{marginRight: '10px'}} type="iconxingxing" className={record.evaluateStar <= i ?   'dis' : 'yellow'} />);
            }
            return (
              <div>
                {items}
              </div>
            )
          }
        },{
          title: '内容',
          dataIndex: 'evaluateContent',
          width: 200
        },{
          title: '状态',
          dataIndex: 'evaluateStatus',
          render: (text,record) => {
            switch(record.evaluateStatus) {
              case (1):
                return <span style={{color: '#41C658'}}>显示</span>
              case (0): 
                return <span style={{color: '#BA1212'}}>隐藏</span>
              default:
            }
          }
        },{
          title: '操作',
          dataIndex:'disStatus',
          render:(text,record) =>{
            switch(record.disStatus) {
              case (0):
                return <Button type="link" onClick={this.dis.bind(this,record.key)}>显示</Button>
              case (1): 
                return <Button type="link" onClick={this.dis.bind(this,record.key)}>隐藏</Button>
              default:
            }
          }
        }
      ]
    }
  }

  componentDidMount(){
    this.getData(this.state.pageSize,this.state.pageNum)
  }
  dis(evaluateId){
    console.log(evaluateId)
    Ajax.POST('/evaluate/forbiddenEvaluate?evaluateId='+ evaluateId ).then(r => {
      if(r.code === 200){
        message.success('操作成功')
        this.getData(this.state.pageSize,this.state.pageNum)
      }else{
        message.error(r.message)
      }
    })
  }
  getData(pageSize,pageNum){
    this.setState({
      tabLoading:  true,
    })
    let url = `/evaluate/list?pageSize=${pageSize}&pageNum=${pageNum}`

    if(this.state.condition){
      url+="&condition=" +this.state.condition 
    }

    Ajax.POST(url).then(r => {
      if(r.code === 200){
          let myData = r.data.list,
          count = r.data.total;
          myData.forEach((item, index, arr) => {
            arr[index].key = item.evaluateId
            arr[index].disStatus = item.evaluateStatus
          });
          this.setState({
            myData : myData,
            count: count,
            tabLoading :false
          })
      }else{
        message.error(r.message)
      }
    })
  }
  searchVal(val) {
    this.state.pageNum = 1;
    if (val) {
      this.state.condition = val
      this.getData(this.state.pageSize, this.state.pageNum)
    } else {
      this.state.condition = ""
      this.getData(this.state.pageSize, this.state.pageNum)
      // message.info('请输入搜索关键词')
    }
  }
  render() {
    var self = this;
    return (
      <div className="my-activity-evaluate-manage">
        <div className="evaluate-manage-content">
          {/* <Breadcrumb className="evaluate-manage-breadcrumb">
            <Breadcrumb.Item>
              <Link to={{pathname: '/my/activity/manage'}}>我发起的活动</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>评价管理</Breadcrumb.Item>
          </Breadcrumb> */}
          <div className="evaluate-manage-card">
            <div className="card-title">评价管理</div>
            <div className="evaluate-list-search">
              <Search
                className="search-input"
                placeholder="请输入"
                enterButton="搜索"
                onSearch={value => this.searchVal(value)}/>
            </div>
            <div className="evaluate-list-table">
              <Table
                columns={this.state.columns}
                dataSource={this.state.myData}
                pagination={{  //分页
                  current:this.state.pageNum,
                  total: this.state.count, //数据总数量
                  pageSize: this.state.pageSize,  //显示几条一页
                  defaultPageSize: this.state.pageSize, //默认显示几条一页
                  showSizeChanger: false,  //是否显示可以设置几条一页的选项

                  onChange(current) {  //点击改变页数的选项时调用函数，current:将要跳转的页数
                    console.log(current)
                    // this.gotoThispage(current, this.state.queryInfo.pageSize);
                    self.state.pageNum = current;
                    self.getData(self.state.pageSize, self.state.pageNum)
                  },
                  showTotal() {  //设置显示一共几条数据
                    return '共 ' + this.total + ' 条数据';
                  }
                }}>
              </Table>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
