import React, { Component } from 'react';
import './App.css';
import homeIndex from './pages/homeIndex'
import deskIndex from './pages/deskIndex'
import { createStore } from 'redux';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import reducers from './reducer/index.js';
import 'braft-editor/dist/index.css'
import 'braft-extensions/dist/color-picker.css'
import BraftEditor from 'braft-editor'
import ColorPicker from 'braft-extensions/dist/color-picker'
import index from './pages/index';

const options = {
  theme: 'light', // 指定取色器样式主题，支持dark和light两种样式
}
BraftEditor.use(ColorPicker(options))

const store = createStore(reducers);

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <BrowserRouter>
          <Switch>
            <Route path="/" exact component={homeIndex}></Route>
            <Route path="/citylist" exact component={homeIndex}></Route>
            <Route path="/user" exact component={index}></Route>
            <Route path="/activity/list" exact component={homeIndex}></Route>
            <Route path="/login" exact component={homeIndex}></Route>
            <Route path="/login/load" exact component={homeIndex}></Route>
            <Route path="/login/test/load" exact component={homeIndex}></Route>
            <Route path="/bind/phone" exact component={homeIndex}></Route>
            <Route path="/my/activity/manage" exact component={deskIndex}></Route>
            <Route path="/my/activity/edit" exact component={deskIndex}></Route>
            <Route path="/my/activity/detail" exact component={deskIndex}></Route>
            <Route path="/my/activity/copy" exact component={deskIndex}></Route>
            <Route path="/my/activity/apply/manage" exact component={deskIndex}></Route>
            <Route path="/activity/apply/manage" exact component={deskIndex}></Route>
            <Route path="/my/activity/checkIn/list" exact component={deskIndex}></Route>
            <Route path="/activity/checkIn/list" exact component={deskIndex}></Route>
            <Route path="/my/activity/quit/manage" exact component={deskIndex}></Route>
            <Route path="/activity/quit/manage" exact component={deskIndex}></Route>
            <Route path="/my/activity/consult/manage" exact component={deskIndex}></Route>
            <Route path="/my/activity/evaluate/manage" exact component={deskIndex}></Route>
            <Route path="/my/finance/manage" exact component={deskIndex}></Route>
            <Route path="/my/activity/income/detail" exact component={deskIndex}></Route>
            <Route path="/my/profile/setting" exact component={deskIndex}></Route>
            <Route path="/my/password/setting" exact component={deskIndex}></Route>
            <Route path="/publish/guide" exact component={deskIndex}></Route>
          </Switch>
        </BrowserRouter>
      </Provider>
    );
  }
}

export default App;
