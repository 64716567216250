import React, { Component } from 'react'
import { Form, Input, Button, Radio, Upload, Checkbox, Icon, Modal, message } from 'antd'
import './index.less'
import { Ajax } from '../../util/ajax'
import eventBus from '../../eventBus'
import uploadImg from "../../util/upload"
const { TextArea } = Input

class UserInfoForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      user: {},
      fileList: [{
        uid: '-1',
        status: 'done',
        response: localStorage.getItem('avatarUrl'),
        thumbUrl: localStorage.getItem('avatarUrl')
      }],
      agreementVisible: false,
      agreementContent: ''
    }
  }

  componentDidMount() {
    this.getSystemSetting()
    eventBus.addListener('hideUserProfileModal', () => {
      this.props.form.resetFields()
    })
  }
  getSystemSetting() {
    Ajax.POST('/sys_setting/get').then(r => {
      if (r.code === 200) {
        this.setState({
          agreementContent: r.data.agreement
        })
      }
    })
  }
  handleSubmit(e) {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        console.log('Received values of form: ', values);
        if (this.state.fileList.length == 0) {
          message.error('照片不能为空')
          return
        }
        values.avatarUrl = this.state.fileList[0].response
        values.userId = localStorage.getItem("userId")
        delete values['agreement']
        console.log('values', values)
        Ajax.POST(`/user/update/profile`, values).then(r => {
          if (r.code === 200) {
            message.success('恭喜你可以开始发起活动了')
            localStorage.setItem('userType', values.type)
            this.props.history.push('/my/activity/edit')
            eventBus.emit('userProfileSaved')
          }
        })
      }
    })
  }
  onChangeUserType(e) {
    console.log(e)
  }
  customRequest = (detail) => {
    uploadImg(detail)
  }
  hideAgreement() {
    this.setState({
      agreementVisible: false
    })
  }
  showAgreement() {
    this.setState({
      agreementVisible: true
    })
  }
  validateAgreement(rule, value, callback) {
    console.log(value)
    if (value === false || value === undefined) {
      callback('请同意并勾选协议，否则不能发起活动哦')
    } else {
      callback()
    }
  }
  validateTag(rule, value, callback) {
    let tags = value.split('；') || []
    if (tags.length > 10) {
      callback('标签最多设置10个')
    }
    tags.forEach((item, index, arr) => {
      if (item.length > 13) {
        callback('每个标签不能超过13个字')
      }
    })
    callback()
  }
  handleChange = (info => this.setState({ fileList: info.fileList }))
  render() {
    const { getFieldDecorator } = this.props.form
    const uploadButton = (
      <div>
        <Icon type="plus" />
        <div className="ant-upload-text">上传图片</div>
      </div>
    )
    const avatarLabel = <span className="ant-form-item-required">头像设置</span>
    return (
      <div id="user-info-form">
        <Form 
        className="user-info-form"
        layout="horizontal"
        labelCol={{ span: 4 }} wrapperCol={{ span: 17 }}
        onSubmit={this.handleSubmit.bind(this)}>
          <Form.Item label="发起方类型">
            {getFieldDecorator('type', {
              rules: [{ required: true, message: '请选择发起方类型' }]
            })(
              <Radio.Group onChange={this.onChangeUserType.bind(this)}>
                <Radio value="INDIVIDUAL">个人</Radio>
                <Radio value="ORGANIZATION">机构</Radio>
              </Radio.Group>
            )}
          </Form.Item>
          <Form.Item label="名称">
            {getFieldDecorator('nickname', {
              initialValue: localStorage.getItem('nickname'),
              rules: [
                { required: true, message: '输入发起方名称' },
                { max: 25, message: '最多输入25个字' }
              ]
            })(
              <Input className="info-input"/>
            )}
          </Form.Item>
          <Form.Item label={avatarLabel}>
            <Upload
              fileList={this.state.fileList}
              customRequest={this.customRequest}
              onChange={this.handleChange}
              listType="picture-card"
              accept="image/*"
            >
              {this.state.fileList.length >= 1 ? null : uploadButton}
            </Upload>
          </Form.Item>
          <Form.Item label="简介">
            {getFieldDecorator('profile', {
              rules: [
                { required: true, message: '请输入简介' },
                { max: 200, message: '最多200个字' }
              ]
            })(
              <TextArea placeholder="请输入简介" autoSize={{ minRows: 3, maxRows: 3 }} className="info-textarea"></TextArea>
            )}
          </Form.Item>
          <Form.Item label="个性标签">
            {getFieldDecorator('tag', {
              rules: [
                { required: true, message: '请输入个性标签' },
                { max: 140, message: '最多140个字' },
                { validator: this.validateTag.bind(this) }
              ]
            })(
              <TextArea autoSize={{ minRows: 3, maxRows: 3 }} className="info-textarea"></TextArea>
            )}
            <div className="tips">标签最多设置10个，中文分号隔开，每个标签最多13个汉字</div>
          </Form.Item>
          <Form.Item wrapperCol={{ span: 14, offset: 4 }}>
            {getFieldDecorator('agreement', {
              rules: [{ validator: this.validateAgreement.bind(this) }],
              valuePropName: 'checked',
            })(
            <Checkbox>
              阅读并同意<Button type="link" style={{ padding: 0 }} onClick={this.showAgreement.bind(this)}>《九五外用户协议》</Button>
            </Checkbox>)}
          </Form.Item>
          <Form.Item wrapperCol={{ span: 17, offset: 4 }}>
            <div className="save-btn-container">
            <Button type="primary" htmlType="submit" className="save-btn">
              <span>保存 </span>
            </Button>
            </div>
          </Form.Item>
        </Form>
        <Modal 
          width={700}
          title={null}
          footer={null}
          onCancel={this.hideAgreement.bind(this)}
          visible={this.state.agreementVisible}>
          <div className="agreement">
            <div className="agreement-title">九五外用户协议</div>
            <div className="agreement-content" dangerouslySetInnerHTML={{ __html: this.state.agreementContent }}></div>
          </div>
        </Modal>
      </div>
    )
  }
}

const userInfoForm = Form.create()(UserInfoForm)

export default userInfoForm
