import React, { Component } from 'react'
import {Layout, Button, Popover, message, Modal, Menu, Badge} from 'antd'
import { Route } from 'react-router-dom'
import{ Link } from 'react-router-dom'
import './index.less'
import { Ajax } from '../../util/ajax';
import logo from '../../asserts/images/logo.png'
import activityManage from '../activityManage'
import activityEdit from '../activityEdit'
import activityDetail from '../activityDetail'
import activityApplyManage from '../activityApplyManage'
import applyManage from '../applyManage'
import activityCheckInList from '../activityCheckInList'
import checkInList from '../checkInList'
import acitivityConsultManage from '../acitivityConsultManage'
import activityEvaluateManage from '../evaluateManage'
import activityCopy from '../activityCopy'
import activityQuitApplyManage from '../activityQuitApplyManage'
import quitApplyManage from '../quitApplyManage'
import financeManage from '../financeManage'
import profileForm from '../profileForm'
import UserInfoForm from '../userInfoForm'
import activityIncomeDetail from '../activityIncomeDetail'
import publishGuide from '../publishGuide'
import eventBus from '../../eventBus/index'
import activityManageIcon from '../../asserts/images/activity@2x.png'
import financeIcon from '../../asserts/images/finance@2x.png'
import profileIcon from '../../asserts/images/profile@2x.png'
import mpQrcodeImg from '../../asserts/images/jiuwuwaimp.png'
import publishGuideIcon from '../../asserts/images/publish_guide.png'
import passwordSetting from '../passwordForm'
const { Header, Content, Sider } = Layout;
const { SubMenu } = Menu;

export default class DeskIndex extends Component {
  constructor(props) {
    super(props)
    this.state = {
      qrcodeImg: null,
      visible: false,
      defaultSelectedKey: window.location.pathname,
      quitApplyCount: 0,
      consultCount: 0,
    }
  }
  componentDidMount() {
    this.getTodoCount()
    eventBus.addListener('userProfileSaved', () => {
      this.hideModal()
    })
    eventBus.addListener('deleteConsult', () => {
      this.getTodoCount()
    })
    eventBus.addListener('answerConsult', () => {
      this.getTodoCount()
    })
    eventBus.addListener('dealQuitApply', () => {
      this.getTodoCount()
    })
  }
  handleClickHomepage() {
    this.props.history.push({pathname: '/'})
  }
  getQrcodeImg() {
    Ajax.POST(`/activity/getQRCode?page=pages/index/index&scene=''`).then(r => {
      if (r.code === 200) {
        this.setState({
          qrcodeImg: `data:image/png;base64,${r.data}`
        })
      }
    })
  }
  getTodoCount() {
    Ajax.POST(`/activity/getTodoCount`).then(r => {
      if (r.code === 200) {
        this.setState({
          quitApplyCount: r.data.quitApplyCount,
          consultCount: r.data.consultCount,
        })
      }
    })
  }
  handleClickPersonCenter() {
    window.location.href = '/my/profile/setting'
  }
  handleClickActivityCenter() {
    window.location.href = '/my/activity/manage'
  }
  logout() {
    var result = Ajax.POST('/manager/loginOut').then(r => {
      if (r.code === 200) {
        localStorage.clear()
        this.setState({
          isLogin: false
        })
        this.props.history.push({pathname: '/'})
      }
    })
  }
  handleClickAddActivity() {
    if (localStorage.getItem('userToken')) {
      if (localStorage.getItem('userType') && (localStorage.getItem('userType') === 'INDIVIDUAL' || localStorage.getItem('userType') === 'ORGANIZATION')) {
        this.props.history.push({pathname: '/my/activity/edit'})
      } else {
        this.setState({
          visible: true
        })
      }
    } else {
      message.warning('未登录，请先登录')
      this.props.history.push({pathname: '/login'})
    }
  }
  hideModal() {
    this.setState({
      visible: false
    })
    eventBus.emit('hideUserProfileModal')
  }
  render() {
    const qrcodeImg = <div style={{ textAlign: "center", width: 160, height: 160 }}>
      <img style={{ width: 160, height: 160 }} src={mpQrcodeImg} alt="" />
    </div>
    const menu = (
      <div>
        <div><Button type="link" style={{ padding: 0 }} onClick={this.handleClickActivityCenter.bind(this)}>活动中心</Button></div>
        <div><Button type="link" style={{ padding: 0 }} onClick={this.handleClickPersonCenter.bind(this)}>个人中心</Button></div>
        <div><Button type="link" style={{ padding: 0 }} onClick={this.logout.bind(this)}>退出登录</Button></div>
      </div>
    )
    return (
      <Layout>
        <Header style={{ position: 'fixed', zIndex: 100, width: '100%', background: '#fff'}}>
          <div className="index-header-per">
            <div className="index-header-content-per">
              <Link to="/">
                <div className="logo-per">
                  <img src={logo} alt="logo" />
                  <span>九五外 来淘趣</span>
                </div>
              </Link>
              <div className="index-header-operation">
                <Button type="primary" ghost style={{ width: 58, height: 36, marginRight: 15 }} onClick={this.handleClickHomepage.bind(this)}>首页 </Button>
                <Popover content={qrcodeImg} placement="bottom">
                  <Button type="primary" ghost style={{ width: 72, height: 36, marginRight: 15 }}>小程序</Button>
                </Popover>
                <Button type="primary" style={{ width: 86, height: 36, fontSize: 14, marginRight: 15 }} onClick={this.handleClickAddActivity.bind(this)} disabled={this.state.publishDisable}>发起活动</Button>
                <Popover content={menu} placement="bottom">
                  <Button type="link" style={{ padding: 0, height: 40 }}><div className="user-avatar"><img src={localStorage.getItem("avatarUrl")} alt=""/></div></Button>
                </Popover>
              </div>
            </div>
            <Modal
              title="完善基本信息"
              visible={this.state.visible}
              onCancel={this.hideModal.bind(this)}
              footer={null}
              width={600}>
              <UserInfoForm history={this.props.history}></UserInfoForm>
            </Modal>
          </div>
        </Header>
        <Content className="desk-content" style={{ textAlign: 'center' }}>
          <Layout style={{ margin: '0 auto', width: 1270, marginTop: 112 }}>
            <Sider width={174} style={{ backgroundColor: '#ffffff', marginRight: 16, height: '100vh' }} className="j-sider">
              <Menu
                mode="inline"
                defaultSelectedKeys={[this.state.defaultSelectedKey]}
                defaultOpenKeys={['sub1', 'sub2', 'sub3', 'sub4']}
                style={{ height: '100%', color: '#303133' }}>
                <SubMenu
                  key="sub1"
                  title={
                    <span>
                      <img src={activityManageIcon} alt=""/>
                      <span style={{ fontWeight: 600 }}>
                        活动中心
                      </span>
                    </span>
                  }>
                  <Menu.Item key="/my/activity/manage">
                    <Link to="/my/activity/manage">
                      活动管理
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="/activity/apply/manage">
                    <Badge dot={this.state.quitApplyCount > 0} offset={[3,2]}>
                      <Link to="/activity/apply/manage">
                        报名管理
                      </Link>
                    </Badge>
                  </Menu.Item>
                  <Menu.Item key="/my/activity/consult/manage">
                    <Badge dot={this.state.consultCount > 0} offset={[3,2]}>
                      <Link to="/my/activity/consult/manage">
                        咨询管理
                      </Link>
                    </Badge>
                  </Menu.Item>
                  <Menu.Item key="/my/activity/evaluate/manage">
                    <Link to="/my/activity/evaluate/manage">
                      评价管理
                    </Link>
                  </Menu.Item>
                </SubMenu>
                <SubMenu
                  key="sub2"
                  title={
                    <span>
                      <img src={financeIcon} alt=""/>
                      <span style={{ fontWeight: 600 }}>
                        账户中心
                      </span>
                    </span>
                  }>
                  <Menu.Item key="/my/finance/manage">
                    <Link to="/my/finance/manage">
                      我的财务
                    </Link>
                  </Menu.Item>
                </SubMenu>
                <SubMenu
                  key="sub3"
                  title={
                    <span>
                      <img src={profileIcon} alt=""/>
                      <span style={{ fontWeight: 600 }}>
                        个人中心
                      </span>
                    </span>
                  }>
                  <Menu.Item key="/my/profile/setting">
                    <Link to="/my/profile/setting">
                      基本信息
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="/my/password/setting">
                    <Link to="/my/password/setting">密码设置</Link>
                  </Menu.Item>
                </SubMenu>
                <SubMenu
                  key="sub4"
                  title={
                    <span>
                      <img src={publishGuideIcon} alt=""/>
                      <span style={{ fontWeight: 600 }}>
                        发布指南
                      </span>
                    </span>
                  }>
                  <Menu.Item key="/publish/guide">
                    <Link to={{pathname: "/publish/guide", state: {anchorName: "register"}}}>
                      登录/注册
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="/publish/guide/publish">
                    <Link to={{pathname: "/publish/guide", state: {anchorName: "publish"}}}>发布活动</Link>
                  </Menu.Item>
                  <Menu.Item key="/publish/guide/manage">
                    <Link to={{pathname: "/publish/guide", state: {anchorName: "manage"}}}>管理活动</Link>
                  </Menu.Item>
                  <Menu.Item key="/publish/guide/withdraw">
                    <Link to={{pathname: "/publish/guide", state: {anchorName: "withdraw"}}}>提现/打款</Link>
                  </Menu.Item>
                  <Menu.Item key="/publish/guide/contact">
                    <Link to={{pathname: "/publish/guide", state: {anchorName: "contact"}}}>联系我们</Link>
                  </Menu.Item>
                </SubMenu>
              </Menu>
            </Sider>
            <Content>
            <Route path="/my/activity/manage" exact component={activityManage}></Route>
            <Route path="/my/activity/edit" exact component={activityEdit}></Route>
            <Route path="/my/activity/detail" exact component={activityDetail}></Route>
            <Route path="/my/activity/copy" exact component={activityCopy}></Route>
            <Route path="/my/activity/apply/manage" exact component={activityApplyManage}></Route>
            <Route path="/activity/apply/manage" exact component={applyManage}></Route>
            <Route path="/my/activity/checkIn/list" exact component={activityCheckInList}></Route>
            <Route path="/activity/checkIn/list" exact component={checkInList}></Route>
            <Route path="/my/activity/quit/manage" exact component={activityQuitApplyManage}></Route>
            <Route path="/activity/quit/manage" exact component={quitApplyManage}></Route>
            <Route path="/my/activity/consult/manage" exact component={acitivityConsultManage}></Route>
            <Route path="/my/activity/evaluate/manage" exact component={activityEvaluateManage}></Route>
            <Route path="/my/finance/manage" exact component={financeManage}></Route>
            <Route path="/my/activity/income/detail" exact component={activityIncomeDetail}></Route>
            <Route path="/my/profile/setting" exact component={profileForm}></Route>
            <Route path="/my/password/setting" exact component={passwordSetting}></Route>
            <Route path="/publish/guide" exact component={publishGuide}></Route>
            </Content>
          </Layout>
        </Content>
      </Layout>
    )
  }
}
