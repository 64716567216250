import React, { Component } from 'react';
import { Table, Input, Breadcrumb, Popconfirm, Select, Modal, message, Button, Divider } from 'antd'
import { Ajax } from '../../util/ajax'
import { Link } from 'react-router-dom'
import './index.less';
import TextArea from 'antd/lib/input/TextArea';
import eventBus from '../../eventBus';
const Search = Input.Search;
const Option = Select.Option;

export default class ActivityConSultManage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      visible: false,
      visible2: false,
      tableLoading: false,
      pageSize: 10,
      pageNum: 1,
      myData: [],
      isAnswered: '-1',
      noPassText: '',
      reasonTit: '',
      currentConsult: null,
      question: '',
      answer: '',
      columns: [
        {
          title: '活动编号',
          dataIndex: 'activityNumber',
          width: 120
        }, {
          title: '活动标题',
          dataIndex: 'activityTitle',
          width: 120
        }, {
          title: '头像',
          dataIndex: 'avatarUrl',
          width: 60,
          render: (record) => {
            return <img src={record} alt="" style={{ width: '40px', height: '40px' }} />
          }
        }, {
          title: '用户',
          dataIndex: 'nickname'
        }, {
          title: '咨询时间',
          dataIndex: 'createdTime'
        }, {
          title: '详情',
          width: 300,
          render: (text, record) => {
            return (
              <div>
                <span>{record.question}</span><br/>
                {record.isAnswered &&
                <span><span style={{ fontWeight: "800" }}>回复：</span>{record.answer}</span>}
              </div>
            )
          }
        }, {
          title: '操作',
          render: (text, record) => {
            return (
              <div>
                <Button type="link" style={{ padding: "0px" }} onClick={this.answer.bind(this, record)}>
                  {record.isAnswered && <span>修改回复</span>}
                  {!record.isAnswered && <span>回复</span>}
                </Button><br/>
                <Popconfirm title="确定确定删除此咨询吗？" onConfirm={this.deleteConsult.bind(this, record.id)} >
                  <Button type="link" style={{ padding: "0px" }}>删除</Button>
                </Popconfirm>
              </div>
            )
          },
        }
      ]
    }
  }
  componentDidMount() {
    this.getData(this.state.pageSize, this.state.pageNum, -1)
  }
  deleteConsult(consultId) {
    Ajax.POST(`/consult/delete?consultId=${consultId}`).then(r => {
      if (r.code === 200) {
        message.success("删除成功")
        eventBus.emit('deleteConsult')
        this.getData(this.state.pageSize, this.state.pageNum, this.state.isAnswered)
      } else {
        message.error(r.message)
      }
    })
  }
  answer(consult) {
    this.setState({
      currentConsult: consult
    })
    if (consult.answer) {
      this.setState({
        answer: consult.answer
      })
    } else {
      this.setState({
        answer: ''
      })
    }
    this.setState({
      visible: true
    })
  }
  handleInputAnswer = (e) => {
    this.setState({
      answer: e.target.value
    })
  }
  getData(pageSize, pageNum, isAnswered) {
    this.setState({
      tableLoading: true,
    })

    let url = `/consult/list?pageSize=${pageSize}&pageNum=${pageNum}&isAnswered=${isAnswered}`
    if (this.state.condition) {
      url += "&condition=" + this.state.condition
    }
    Ajax.POST(url).then(r => {
      if (r.code === 200) {
        let myData = r.data.list,
          count = r.data.total;
        myData.forEach((item, index, arr) => {
          arr[index].key = item.id
        });
        this.setState({
          myData: myData,
          count: count,
          tableLoading: false
        })
      } else {
        message.error(r.message)
      }
    })
  }
  searchVal(val) {
    this.setState({
      pageNum: 1
    })
    if (val) {
      this.state.condition = val
      this.getData(this.state.pageSize, 1, this.state.isAnswered)
    } else {
      this.state.condition = ""
      this.getData(this.state.pageSize, 1, this.state.isAnswered)
      // message.info('请输入搜索关键词')
    }
  }
  findConsults(val) {
    this.setState({
      isAnswered: val
    })
    this.getData(this.state.pageSize, 1, val)
  }
  hideModal() {
    this.setState({
      visible: false
    })
  }
  answerSubmit() {
    if (this.state.answer === '') {
      message.error("请输入回复内容")
    } else {
      Ajax.POST(`/consult/update`, {
        id: this.state.currentConsult.id,
        answer: this.state.answer
      }).then(r => {
        if (r.code === 200) {
          message.success("回复成功")
          this.setState({
            visible: false
          })
          eventBus.emit('answerConsult')
          this.getData(this.state.pageSize, this.state.pageNum, this.state.isAnswered)
        } else (
          message.error(r.message)
        )
      })
    }
  }
  render() {
    var self = this;
    return (
      <div className="my-activity-consult-manage">
        <div className="consult-manage-content">
          {/* <Breadcrumb className="consult-manage-breadcrumb">
            <Breadcrumb.Item>
              <Link to={{pathname: '/my/activity/manage'}}>活动中心</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item><span style={{ color: "#E35F4B" }}>咨询管理</span></Breadcrumb.Item>
          </Breadcrumb> */}
        <div className="consult-manage-card">
          <div className="card-title">咨询管理</div>
          <div className="consult-list-search">
            <Select onChange={this.findConsults.bind(this)} value={this.state.isAnswered} defaultValue="-1" className="search-select" >
              <Option value="-1">全部</Option>
              <Option value="0">未回复</Option>
              <Option value="1">已回复</Option>
            </Select>
            <Search
              className="search-input"
              placeholder="请输入"
              enterButton="搜索 "
              onSearch={value => this.searchVal(value)}
            />
          </div>
          <div className="consult-list-table">
            <Table dataSource={this.state.myData}
              columns={this.state.columns}
              loading={this.state.tableLoading}
              pagination={{  //分页
                current:this.state.pageNum,
                total: this.state.count, //数据总数量
                pageSize: this.state.pageSize,  //显示几条一页
                defaultPageSize: this.state.pageSize, //默认显示几条一页
                showSizeChanger: false,  //是否显示可以设置几条一页的选项

                onChange(current) {  //点击改变页数的选项时调用函数，current:将要跳转的页数
                  console.log(current)
                  // this.gotoThispage(current, this.state.queryInfo.pageSize);
                  self.state.pageNum = current;
                  self.getData(self.state.pageSize, self.state.pageNum, self.state.isAnswered)
                },
                showTotal() {  //设置显示一共几条数据
                  return '共 ' + this.total + ' 条数据';
                }
              }}
            ></Table>
          </div>
        </div>
        <Modal
          title="回复咨询"
          visible={this.state.visible}
          onOk={this.answerSubmit.bind(this)}
          onCancel={this.hideModal.bind(this)}
          okText="确认"
          cancelText="取消">
          {this.state.currentConsult &&
          <div style={{ marginBottom: "20px" }}><span style={{ fontWeight: "800" }}>问题：</span>{this.state.currentConsult.question}</div>}
          <TextArea value={this.state.answer} onChange={this.handleInputAnswer.bind(this)} placeholder="请输入回复内容" style={{ height: '100px' }}></TextArea>
        </Modal>
        </div>
      </div>
    )
  }
}
