import React, {Component} from 'react'
import { Tabs, Button, Table, Dropdown, Menu, Icon, message, Modal, Spin, Popconfirm, Popover, Row, Col, Divider, Input, Select } from 'antd'
import { Ajax } from '../../util/ajax'
import './index.less'
import { Link } from 'react-router-dom'
import activities from '../../filter/activities'
import UserInfoForm from '../userInfoForm'
import eventBus from '../../eventBus'
import { parseAmount } from '../../util/util'
import ActivitySkuForm from '../activitySkuForm'
import moment from 'moment'
const { TabPane } = Tabs
const Search = Input.Search
const Option = Select.Option
const { confirm } = Modal

export default class ActivityManage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      currentUserId: 0,
      currentActivityId: 0,
      activityList: [],
      pageNum: 1,
      pageSize: 10,
      condition: '',
      count: 0,
      tableLoading: false,
      qrcodeLoading: false,
      visible: false,
      activityQRCodeImg: '',
      downloadActivityId: 0,
      userProfileVisible: false,
      deleteModalVisible: false,
      deleteActivityId: 0,
      downloadUrl: '',
      status: '',
      skuModalVisible: false,
      publishDisable: (localStorage.getItem('isAllowPublish') && localStorage.getItem('isAllowPublish') == 0 ) ? true : false,
      columns: [
        {
          title: '活动名称',
          dataIndex: 'activityTitle',
          width: 120,
        }, {
          title: '图片',
          dataIndex: 'activityImgUrl',
          width: 80,
          render: (record) => {
              console.log(record)
              return <img src={record} alt="" style={{ width: '53.63px', height: '36px' }} />
          }
        }, {
          title: '门票价格',
          dataIndex: 'activityPrice',
          width: 100
        }, {
          title: '活动状态',
          width: 140,
          render: (text, record) => {
            const content = (
              <div>
                {record.remarkList && 
                <div style={{ width: 400 }}>
                  {record.remarkList.map((item, index) => {
                    return (
                      <Row key={index}>
                        <Col span={10}>{item.createdTime}</Col>
                        <Col span={10}>{item.remark}</Col>
                      </Row>
                    )
                  })}
                </div>}
                {/* <div style={{ textAlign: "right"}}>
                  <Button type="primary" onClick={this.hideRemark.bind(this)}>关闭</Button>
                </div> */}
              </div>
            )
            const title = <span>备注详情</span>
            let acticityStatus = <span>{activities.status[record.status]}</span>
            if (record.status === 'published' && moment(record.activityEndTime).isBefore(moment().format('YYYY-MM-DD HH:mm:ss'))) {
              acticityStatus = <span>已结束</span>
            }
            return (
              <div>
                {acticityStatus}
                {(record.status === 'rejected' || record.status === 'offline') &&
                <Popover
                placement="right"
                title={title}
                content={content}>
                  <Button type="link" size="small">
                    <Icon type="info-circle"/>
                  </Button>
                </Popover>}
                {(record.status === 'draft' || record.status === 'rejected') &&
                <span><br/>
                  <div className="submit-review-btn">
                    <Popconfirm placement="top" title="确认提交审核吗？" onConfirm={this.submitReviewing.bind(this, record.activityId)} okText="确认" cancelText="取消">
                      <Button type="primary" ghost size="small" style={{ marginTop: 8 }} >提交审核</Button>
                    </Popconfirm>
                  </div>
                </span>}
              </div>
            )
          }
        }, {
          title: '活动时间',
          width: 310,
          render: (text, record) => {
            return <span>{record.activityStartTime}至{record.activityEndTime}</span>
          }
        }, {
          title: '活动地址',
          dataIndex: 'activityAddress',
          width: 150,
          render: (text, record) => {
            return (
              <div>
                {record.activitySiteSort === 0 && 
                <span>线上</span>}
                {record.activitySiteSort === 1 && 
                <span>{record.cityName}：{record.activityAddress}
                </span>}
              </div>
            )
          }
        }, {
          title: '操作',
          width: 120,
          render: (text, record) => {
            const menu = (
              <Menu>
                {(localStorage.getItem("isAllowPublish") == 1 && (record.status === 'draft' || record.status === 'rejected')) &&
                <Menu.Item key="0">
                  <Link to={{pathname: '/my/activity/edit', state: {activityId: record.activityId}}}>编辑修改</Link>
                </Menu.Item>}
                {record.status === 'published' &&
                <Menu.Item key="1">
                  <span type="link" className="operate-btn" onClick={this.handleClickQrcode.bind(this, record.activityId)}>下载二维码</span>
                </Menu.Item>}
                {/* <Menu.Item key="2">
                  <Link to={{pathname: '/my/activity/apply/manage', state: {activityId: record.activityId}}}>报名管理</Link>
                </Menu.Item> */}
                <Menu.Item key="5">
                  <span className="operate-btn" onClick={this.copyActivity.bind(this, record.activityId)} disabled={this.state.publishDisable}>复制活动</span>
                </Menu.Item>
                {record.status === 'draft' &&
                <Menu.Item key="6">
                  <span className="operate-btn" onClick={this.showDeleteConfirm.bind(this, record.activityTitle, record.activityId)}>删除活动</span>
                </Menu.Item>}
                {record.status === 'published' && moment(record.activityEndTime).isSameOrAfter(moment(moment().format('YYYY-MM-DD HH:mm'))) &&
                <Menu.Item key="7">
                  <span className="operate-btn" onClick={this.modifyActivityType.bind(this, record.activityId)}>修改库存</span>
                </Menu.Item>}
                {record.status === 'published' && moment(record.activityEndTime).isSameOrAfter(moment(moment().format('YYYY-MM-DD HH:mm'))) &&
                <Menu.Item key="8">
                  <span className="operate-btn" onClick={this.showOfflineConfirm.bind(this, record.activityTitle, record.activityId)}>下线活动</span>
                </Menu.Item>}
              </Menu>
            )
            return <div>
              <Dropdown.Button onClick={this.handleButtonClick.bind(this, record.activityId)} overlay={menu}>
                查看详情
              </Dropdown.Button>
            </div>
          }
        }
      ]
    }
  }

  componentDidMount() {
    this.getActivityList(this.state.pageSize, this.state.pageNum, this.state.status)
    eventBus.addListener('modifySkuSuccess', () => {
      this.hideSkuModalVisible()
    })
  }
  submitReviewing(activityId) {
    Ajax.POST(`/activity/submit/review?activityId=${activityId}`).then(r => {
      if (r.code === 200) {
        message.success('提交审核成功')
        this.getActivityList(this.state.pageSize, this.state.pageNum)
      } else {
        message.error(r.message)
      }
    })
  }
  copyActivity(activityId) {
    Ajax.POST(`/activity/copy?activityId=${activityId}`).then(r => {
      if (r.code === 200) {
        message.success("活动复制成功")
        this.getActivityList(this.state.pageSize, 1)
      } else {
        message.error(r.message)
      }
    })
  }
  deleteActivity(activityId) {
    Ajax.POST(`/activity/delete?activityId=${activityId}`).then(r => {
      if (r.code === 200) {
        message.success("活动删除成功")
        this.getActivityList(this.state.pageSize, 1, this.state.status)
      } else {
        message.error(r.message)
      }
    })
  }
  modifyActivityType(activityId) {
    this.setState({
      currentActivityId: activityId,
      skuModalVisible: true
    })
  }
  handleClickQrcode(activityId) {
    this.setState({
      downloadUrl: `${window.location.origin}/tcback/activity/download/qrcode?page=pages/activity/detail/index&scene=${activityId}`,
      qrcodeLoading: true,
      visible: true,
      downloadActivityId: activityId
    })
    Ajax.POST(`/activity/getQRCode?page=pages/activity/detail/index&scene=${activityId}`).then(r => {
      if (r.code === 200) {
        this.setState({
          qrcodeLoading: false,
          activityQRCodeImg: `data:image/png;base64,${r.data}`
        })
      }
    })
  }
  handleCancel() {
    this.setState({
      visible: false
    })
  }
  showDeleteConfirm(acticityTitle, activityId) {
    let that = this
    confirm({
      title: `确认删除活动《${acticityTitle}》?`,
      icon: <Icon type="exclamation-circle" />,
      okText: '确认',
      okType: 'danger',
      cancelText: '取消',
      onOk() {
        that.deleteActivity(activityId)
      },
      onCancel() {
      },
    })
  }
  showOfflineConfirm(acticityTitle, activityId) {
    let that = this
    confirm({
      title: `确认下线活动《${acticityTitle}》?`,
      icon: <Icon type="exclamation-circle" />,
      okText: '确认',
      okType: 'danger',
      cancelText: '取消',
      onOk() {
        that.offlineActivity(activityId, acticityTitle)
      },
      onCancel() {
      },
    })
  }
  
  offlineActivity(activityId, acticityTitle) {
    let that = this
    Ajax.POST('/activity/offline', {
			activityId: activityId,
			remark: "活动下线"
		}).then(r => {
			if (r.code === 200) {
        if (r.data === 'Success') {
          message.success('活动下线成功')
          this.getActivityList(this.state.pageSize, 1, this.state.status)
        } else if (r.data === 'Failed') {
          Modal.warning({
            title: `该活动已有人报名，暂不能下线`,
            content: '需要处理完所有取消报名，保证活动没有人报名的情况下，方可下线活动',
          })
        }
			} else {
				message.error(r.message)
			}
		})
  }

  hideUserProfileModal() {
    this.setState({
      userProfileVisible: false
    })
    eventBus.emit('hideUserProfileModal')
  }
  getActivityList(pageSize, pageNum, status) {

    this.setState({
      tableLoading: true
    })
    var url = `/activity/get/personal/activities?pageSize=${pageSize}&pageNum=${pageNum}&status=${status}`

    if (this.state.condition) {
        url += '&condition=' + this.state.condition
    }
    Ajax.POST(url).then(r => {
      if (r.code === 200) {
        console.log('data', r.data)
        let myData = r.data.list,
          count = r.data.total
        myData.forEach((item, index, arr) => {
          arr[index].key = item.activityId
        });
        this.setState({
          activityList: myData,
          count: count,
          tableLoading: false
        })
      } else {
        message.error(r.message)
      }
    })
  }
  handleButtonClick(activityId) {
    this.props.history.push({pathname: '/my/activity/detail', state: {activityId: activityId}})
  }
  callback(key) {
    if (key === '2') {
      this.props.history.push({pathname: '/my/finance/manage'})
    } else if (key === '3') {
      this.props.history.push({pathname: '/my/profile/setting'})
    }
  }
  createActivity() {
    if (localStorage.getItem('userType') && (localStorage.getItem('userType') === 'INDIVIDUAL' || localStorage.getItem('userType') === 'ORGANIZATION')) {
      this.props.history.push({pathname: '/my/activity/edit'})
    } else {
      this.setState({
        userProfileVisible: true
      })
    }
  }
  searchVal(val) {
    this.setState({
      pageNum: 1
    })
    if (val) {
      this.state.condition = val
      this.getActivityList(this.state.pageSize, 1, this.state.status)
    } else {
      this.state.condition = ""
      this.getActivityList(this.state.pageSize, 1, this.state.status)
      // message.info('请输入搜索关键词')
    }
  }
  findActivities(value) {
    this.setState({
      status: value
    })
    this.getActivityList(this.state.pageSize, 1, value)
  }
  hideSkuModalVisible() {
    this.setState({
      skuModalVisible: false
    })
  }
  render() {
    var self = this
    return (
      <div id="my-activity-manage">
        <div className="my-activity-manage-content">
          <div className="card-title">活动管理</div>
          <div className="activity-list-header">
            <div className="activity-list-search">
              <Select onChange={this.findActivities.bind(this)} value={this.state.status} defaultValue="-1" className="search-select" >
                <Option value="">全部</Option>
                <Option value="draft">草稿</Option>
                <Option value="reviewing">审核中</Option>
                <Option value="published">已发布</Option>
                <Option value="rejected">审核未通过</Option>
                <Option value="offline">已下线</Option>
                <Option value="end">已结束</Option>
              </Select>
              <Search
                className="search-input"
                placeholder="请输入"
                enterButton="搜索 "
                onSearch={value => this.searchVal(value)}
              />
            </div>
            <div>
              <Button 
              type="primary"
              className="publish-activity-btn"
              disabled={this.state.publishDisable}
              onClick={this.createActivity.bind(this)}><Icon type="plus" style={{ width: 12, height: 12, marginRight: 8 }}/><span>发布新活动</span></Button>
            </div>
          </div>
        <div className="acticity-list-table">
          <Table
            dataSource={this.state.activityList}
            columns={this.state.columns}
            loading={this.state.tableLoading}
            pagination={{  //分页
              total: this.state.count, //数据总数量
              pageSize: this.state.pageSize,  //显示几条一页
              defaultPageSize: this.state.pageSize, //默认显示几条一页
              showSizeChanger: false,  //是否显示可以设置几条一页的选项

              onChange(current) {  //点击改变页数的选项时调用函数，current:将要跳转的页数
                  console.log(current)
                  // this.gotoThispage(current, this.state.queryInfo.pageSize);
                  self.state.pageNum = current;
                  self.getActivityList(self.state.pageSize, self.state.pageNum, self.state.status)
              },
              showTotal() {  //设置显示一共几条数据
                  return '共 ' + this.total + ' 条数据';
              }
            }}>
          </Table>
        </div>
        <Modal
          visible={this.state.visible}
          onCancel={this.handleCancel.bind(this)}
          footer={null}
          width="400px"
        >
          <div className="minipro-qrcode-content">
            <div className="qrcode">
              {this.state.qrcodeLoading && <Spin size="large" />}
              {!this.state.qrcodeLoading && <img src={this.state.activityQRCodeImg} alt="" />}
            </div>
            <span><a href={this.state.downloadUrl} _blank>下载小程序码</a></span>
          </div>
        </Modal>
        <Modal
          title="完善基本信息"
          visible={this.state.userProfileVisible}
          onCancel={this.hideUserProfileModal.bind(this)}
          footer={null}
          width={600}>
          <UserInfoForm history={this.props.history}></UserInfoForm>
        </Modal>
        <Modal
          title="修改库存"
          visible={this.state.skuModalVisible}
          onCancel={this.hideSkuModalVisible.bind(this)}
          footer={null}
          width={660}>
          <ActivitySkuForm history={this.props.history} activityId={this.state.currentActivityId}></ActivitySkuForm>
        </Modal>
        </div>
      </div>
    )
  }
}
