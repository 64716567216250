import React, { Component } from 'react'
import {Carousel, Button, Row, Col, Icon, Spin, Divider } from 'antd'
//import{ Link} from 'react-router-dom'
import './index.less'
import activityIcon from '../../asserts/images/Rectangle@2x.png'
import qrcodeIcon from '../../asserts/images/qrcode@2x.png'
import offlineActivtyIcon from '../../asserts/images/offlineActivity@2x.png'
import onlineActivityIcon from '../../asserts/images/onlineActivity@2x.png'
import beianImg from '../../asserts/images/beian.png'
import { Ajax } from '../../util/ajax'
import eventBus from '../../eventBus/index'
import { Link } from 'react-router-dom'

export default class Homepage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      bannerData: [],
      activitySorts: [],
      activitySortId: 0,
      activities: [],
      recommendActivities: [],
      pageNum: 1,
      pageSize: 12,
      currentCityId: localStorage.getItem('currentCityId') ? localStorage.getItem('currentCityId') : 0,
      currentCityName: localStorage.getItem('currentCityName') ? localStorage.getItem('currentCityName') : '',
      hotCities: [],
      activityTitle: '',
      targetOffset: undefined,
      activityQRCodeImg: '',
      showQRCodeActivityId: 0,
      qrcodeLoading: false,
      isShowActivityQRCode: false,
      activityType: '',
      hasMore: false,
    }
  }
  componentDidMount() {
    this.setState({
      targetOffset: window.innerHeight / 2,
    })
    this.getBannerList()
    this.getAllActivitySort()
    let cityId = this.state.currentCityId
    let cityName = this.state.currentCityName
    let title = this.state.activityTitle
    if (this.props.history.location.state && this.props.history.location.state.cityId) {
      cityId = this.props.history.location.state.cityId
      cityName = this.props.history.location.state.cityName
      this.setState({
        currentCityId: cityId,
        currentCityName: cityName
      })
    }
    if (this.props.history.location.state && this.props.history.location.state.title) {
      title = this.props.history.location.state.title
    }
    this.getRecommendActivityList(cityId)
    this.getActivityList(cityId, this.state.activitySortId, this.state.pageSize, this.state.pageNum, title)
    eventBus.addListener('headerCityChange', (city) => {
      this.setState({ 
        currentCityId: city.id,
        currentCityName: city.name,
        pageNum: 1
      })
      this.getActivityList(city.id, this.state.activitySortId, this.state.pageSize, 1, this.state.activityTitle)
      this.getRecommendActivityList(city.id)
    })
    window.addEventListener('scroll', this.handleScroll.bind(this))
  }
  handleScroll(event) {
    let clientHeight = document.documentElement.clientHeight; //可视区域高度
    let scrollTop  = document.documentElement.scrollTop;  //滚动条滚动高度
    let scrollHeight = document.documentElement.scrollHeight; //滚动内容高度
    let res = scrollHeight-scrollTop-clientHeight
    if (res < 1) {
      this.handerLoadMore()
    }
  }
  getBannerList() {
    Ajax.POST('/banner/list').then(r => {
      if(r.code === 200) {
          let bannerData = r.data
          this.setState({
            bannerData: bannerData
          })
      }
    })
  }
  getAllActivitySort() {
    Ajax.POST('/activitySort/get/has_activity').then(r => {
      let activitySorts = [{
        id: 0,
        name: '全部'
      }];
      if (r.code === 200) {
        this.setState({
          activitySorts: activitySorts.concat(r.data)
        })
      } else {
        this.setState({
          activitySorts: activitySorts
        })
      }
    })
  }
  getActivityList(cityId, sortId, pageSize, pageNum, title='') {
    Ajax.POST(`/activity/list?cityId=${cityId}&sortId=${sortId}&title=${title}&pageNum=${pageNum}&pageSize=${pageSize}`).then(r => {
      if (r.code === 200) {
        this.setState({
          activities: r.data.activityList.list,
          hasMore: r.data.activityList.nextPage > 0 ? true : false
        })
      }
    })
  }
  getRecommendActivityList(cityId) {
    Ajax.POST(`/activity/list/recommend?cityId=${cityId}`).then(r => {
      if (r.code === 200) {
        this.setState({
          recommendActivities: r.data
        })
      }
    })
  }
  handerLoadMore() {
    if (!this.state.hasMore) {
      return
    }
    Ajax.POST(`/activity/list?cityId=${this.state.currentCityId}&sortId=${this.state.activitySortId}&title=${this.state.activityTitle}&pageNum=${this.state.pageNum + 1}&pageSize=${this.state.pageSize}`).then(r => {
      if (r.code === 200) {
        this.setState({
          activities: this.state.activities.concat(r.data.activityList.list),
          pageNum: r.data.activityList.pageNum,
          hasMore: r.data.activityList.nextPage > 0 ? true : false
        })
      }
    })
  }
  changeActivitySort(sortId) {
    this.setState({
      activitySortId: sortId,
      pageNum: 1
    })
    this.getActivityList(this.state.currentCityId, sortId, this.state.pageSize, 1, this.state.activityTitle)
  }
  showActivityQRCode(activityId,activityType) {
    this.setState({
      activityType: activityType,
      qrcodeLoading: true,
      isShowActivityQRCode: true,
      showQRCodeActivityId: activityId,
    })
    Ajax.POST(`/activity/getQRCode?page=pages/activity/detail/index&scene=${activityId}`).then(r => {
      if (r.code === 200) {
        this.setState({
          activityQRCodeImg: `data:image/png;base64,${r.data}`,
          qrcodeLoading: false
        })
      }
      console.log('this.state.activityQRCodeImg',this.state.activityQRCodeImg)
    })
  }
  hideActivityQRCode() {
    this.setState({
      isShowActivityQRCode: false,
      acticityQRCodeImg: '',
      showQRCodeActivityId: 0,
      activityType: ''
    })
  }
  searchActivity(title) {
    this.setState({
      activityTitle: title,
      activitySortId: 0,
      pageNum: 1
    })
    this.getActivityList(this.state.currentCityId, this.state.activitySortId, this.state.pageSize, this.state.pageNum, title)
  }
  handleClickRecord() {
    window.open("http://www.beian.miit.gov.cn") 
  }
  render() {
    return (
      <div style={{ textAlign: "center"}}>
        <div style={{ width: 1140, height: 425, margin: '0 auto', marginTop: 64 }}>
        <Carousel autoplay>
          {
            this.state.bannerData.map((item, index) => {
              return <div key={index}><img src={ item.imgUrl } alt="banner" className="carousel-cover"/></div>
            })
          }
        </Carousel>
        </div>
        <div id="activity-list">
          {this.state.recommendActivities.length > 0 &&
          <div id="recommend-activity" className="recommend-activity">
            <div className="recommend-activity-title">
              <div><img src={activityIcon} alt="" /></div>
              <div>推荐活动</div>
            </div>
            <div className="recommend-activity-list">
              {this.state.recommendActivities.length > 0 && 
              <Row gutter={19}>
                {this.state.recommendActivities.map((item, index) => {
                  return <Col span={6} key={index}>
                    <div className="activity-item">
                      <div className="item-top">
                        <div className="activity-img">
                          <img src={item.activityImgUrl} alt="" className="carousel-cover"/>
                        </div>
                        <div className="image-overlay"></div>
                        <div className="activity-title">{item.activityTitle}</div>                 <div className="activity-qrcode-btn">
                          <Button className="btn" type="link" onMouseOver={this.showActivityQRCode.bind(this, item.activityId, 'recommend')} onMouseOut={this.hideActivityQRCode.bind(this)}>
                            <img src={qrcodeIcon} alt="" />
                          </Button>
                        </div>
                        {this.state.isShowActivityQRCode && item.activityId === this.state.showQRCodeActivityId && "recommend" === this.state.activityType && <div className="activity-qrcode">
                          {this.state.qrcodeLoading && <Spin />}
                          {!this.state.qrcodeLoading && <img src={this.state.activityQRCodeImg} alt="" />}
                        </div>}
                        <div className="activity-site">
                          <Icon type="environment" />{item.activityAddress}
                        </div>
                        {/* <div className="activity-address">
                          <Icon type="environment" />{item.activityAddress}
                          { item.activityAddress === '线上' &&
                          <img src={onlineActivityIcon} alt="" />}
                          {item.activityAddress === '线下' &&
                          <img src={offlineActivtyIcon} alt="" />}
                        </div> */}
                      </div>
                      <div className="item-bottom">
                        <div className="taker-avatar">
                          <img src={item.userAvatarUrl} alt="" />
                        </div>
                        <div className="item-bottom-bd">
                          <div className="taker-nickname">{item.userNickname}</div>
                          <div className="activity-time">时间: {item.activityStartTime}</div>
                          <div className="acticity-max-people">名额: {item.activityMaxPeople}人
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                })}
              </Row>}
              {this.state.recommendActivities.length <= 0 && 
              <div className="activity-list-none">
                <div>
                  <div className="none-icon"><Icon type="file-search"/></div>
                  <div><span>暂无活动</span></div>
                </div>
              </div>}
            </div>
          </div>}
          <div id="all-activity">
            <div className="all-activity-title">
              <div><img src={activityIcon} alt="" /></div>
              <div style={{ marginRight: 30 }}>全部活动</div>
              <div id="activity-filter" className="activity-filter">
                {
                  this.state.activitySorts.map((item, index) => {
                  return <Button className={ this.state.activitySortId == item.id ? "activity-sort sort-active" : "activity-sort" } type="link" key={index} onClick={this.changeActivitySort.bind(this, item.id)}>{item.name}</Button>
                  })
                }
              </div>
            </div>
            <div className="all-activity-list">
              {this.state.activities.length > 0 &&
              <Row>
                {this.state.activities.map((item, index) => {
                  return <Col span={6} key={index}>
                    <div className="activity-item">
                      <div className="item-top">
                        <div className="activity-img">
                          <img src={item.activityImgUrl} alt="" className="carousel-cover"/>
                        </div>
                        <div className="image-overlay"></div>
                        <div className="activity-title">{item.activityTitle}</div>
                        <div className="activity-qrcode-btn">
                          <Button className="btn" type="link" onMouseOver={this.showActivityQRCode.bind(this, item.activityId, "all")} onMouseOut={this.hideActivityQRCode.bind(this)}>
                            <img src={qrcodeIcon} alt="" />
                          </Button>
                        </div>
                        {this.state.isShowActivityQRCode && item.activityId === this.state.showQRCodeActivityId && "all" === this.state.activityType && <div className="activity-qrcode">
                          {this.state.qrcodeLoading && <Spin />}
                          {!this.state.qrcodeLoading && <img src={this.state.activityQRCodeImg} alt="" />}
                        </div>}
                        <div className="activity-site">
                          <Icon type="environment" />{item.activityAddress}
                        </div>
                        {/* <div className="activity-address">
                          { item.activityAddress === '线上' &&
                          <img src={onlineActivityIcon} alt="" />}
                          {item.activityAddress === '线下' &&
                          <img src={offlineActivtyIcon} alt="" />}
                        </div> */}
                      </div>
                      <div className="item-bottom">
                        <div className="taker-avatar">
                          <img src={item.userAvatarUrl} alt="" />
                        </div>
                        <div className="item-bottom-bd">
                          <div className="taker-nickname">{item.userNickname}</div>
                          <div className="activity-time">时间: {item.activityStartTime}</div>
                          <div className="acticity-max-people">名额: {item.activityMaxPeople}人
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                })}
              </Row>}
              {this.state.activities.length <= 0 && 
              <div className="activity-list-none">
                <div>
                  <div className="none-icon"><Icon type="file-search"/></div>
                  <div><span>暂无活动</span></div>
                </div>
              </div>} 
            </div>
          </div>
        </div>
        <div className="index-footer" style={{ marginTop: 20 }}>
          <div>
            <span>版权所有：杭州久五外网络科技有限公司</span>
            <Divider type="vertical" />
            <a target="_blank" href="http://www.beian.miit.gov.cn/" style={{ padding: 0, color: "#616161" }}>浙ICP备19006753号</a>
            <Divider type="vertical" />
            <a target="_blank" href="http://www.beian.miit.gov.cn/" style={{ padding: 0, color: "#616161" }}><img src={beianImg} style={{ height: 16 }} alt=""/>浙公网安备33010602010846号</a>
          </div>
          <div><span>九五外 来淘趣 v1.0.0 </span><Icon style={{ fontSize: 12 }} type="copyright" /> 95wai.com All Rights Reserved.</div>
        </div>
      </div>
    )
  }
}
