import React, { Component } from 'react'
import { Spin } from 'antd'
import './index.less'
import { Ajax } from '../../util/ajax';
import eventBus from '../../eventBus/index'
import LoginForm from '../loginForm'
import { Link } from 'react-router-dom';

export default class LoginLoading extends Component {
  constructor(props) {
    super(props)
    this.state = {
    }
  }

  componentDidMount() {
    const params = new URLSearchParams(this.props.location.search)
    const code = params.get('code')
    if (code !== null && code !== undefined) {
      Ajax.POST(`/wx/kf/login?code=${code}`).then(r => {
        if (r.code === 200) {
          if (r.data.type === 'needBindPhone') {
            localStorage.setItem('unionid', r.data.unionid)
            window.location.href = window.location.protocol + '//' + window.location.host + "/bind/phone"
          } else if (r.data.type === 'loginOk') {
            localStorage.setItem('phone', r.data.phone)
            localStorage.setItem('userId', r.data.userId)
            localStorage.setItem('nickname', r.data.nickname)
            localStorage.setItem('avatarUrl', r.data.avatarUrl)
            localStorage.setItem('isLogin', true)
            localStorage.setItem('userToken',  r.data.token)
            localStorage.setItem('currentCityId', r.data.currentCityId)
            localStorage.setItem('currentCityName', r.data.currentCityName)
            localStorage.setItem('isAllowPublish', r.data.isAllowPublish)
            localStorage.setItem('commissionRate', r.data.commissionRate)
            if (r.data.userType) {
              localStorage.setItem('userType', r.data.userType)
            }
            eventBus.emit('userLogined')
            window.location.href = window.location.protocol + '//' + window.location.host
          }
        }
      })
    }
  }

  render() {
    return (
      <div className="login-container">
       <div style={{ textAlign: 'center' }}>
        <Spin size="large" style={{ marginRight: 10 }}/>
        <span>正在获取微信授权</span>
       </div>
      </div>
    )
  }
}
