import React, {Component} from 'react'
import { Table, Tabs, message, Breadcrumb, Input, Button } from 'antd'
import { Ajax } from '../../util/ajax'
import { Link } from 'react-router-dom'
import './index.less'
const { TabPane } = Tabs
const Search = Input.Search

export default class ApplyManage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      applyList: [],
      pageNum: 1,
      pageSize: 10,
      condition: '',
      count: 0,
      tableLoading: false,
      applyColumns: [
        {
          title: '活动编号',
          dataIndex: "activity_number",
          width: 120
        }, {
          title: "活动名称",
          dataIndex: 'activity_title',
          width: 270
        }, {
          title: '活动日期',
          width: 360,
          render: (text, record) => {
            return <span>{record.activity_start_time} 至 {record.activity_end_time} </span>
          }
        }, {
          title: '报名人数',
          render: (text, record) => {
            return <span>{record.people} / {record.stock}</span>
          }
        }, {
          title: '报名人员',
          dataIndex: 'opeartion',
          width: 120,
          render: (text, record) => {
            return <Link to={{pathname: '/my/activity/apply/manage', state: {activityId: record.activity_id}}}>查看详情</Link>
          }
        }
      ],
    }
  }
  componentDidMount() {
    this.getApplyList(this.state.pageSize, this.state.pageNum)
  }
  getApplyList(pageSize, pageNum) {
    this.setState({
      tableLoading: true
    })
    let url = `/apply/list/all?pageSize=${pageSize}&pageNum=${pageNum}`
    if (this.state.condition) {
      url += '&condition=' + this.state.condition
    }
    Ajax.POST(url).then( r =>{
      if(r.code === 200){
        let myData = r.data.list
        let count = r.data.total
        myData.forEach((item, index, arr) => {
            arr[index].key = index
        });
        this.setState({
          applyList: myData,
          count: count,
          tableLoading: false
        })
      }else{
        message.error(r.message)
      }
    })
  }
  searchVal(val) {
    this.state.pageNum = 1;
    if (val) {
      this.state.condition = val
      this.getApplyList(this.state.pageSize, this.state.pageNum)
    } else {
      this.state.condition = ""
      this.getApplyList(this.state.pageSize, this.state.pageNum)
    }
  }
  handleTabChange(value) {
    this.props.history.push({pathname: '/activity/quit/manage'})
  }
  render() {
    var self = this
    return (
      <div className="apply-manage">
        <div className="apply-manage-content">
        <div className="apply-manage-card">
        <div className="card-title">报名管理</div>
        <Tabs onChange={this.handleTabChange.bind(this)} activeKey="1" style={{ marginTop: 20 }}>
          <TabPane tab="报名列表" key="1">
          </TabPane>
          <TabPane tab="取消报名" key="2">
          </TabPane>
        </Tabs>
        <div className="quit-apply-list-search">
          <Search
            className="search-input"
            placeholder="请输入"
            enterButton="搜索 "
            onSearch={value => this.searchVal(value)}
          />
        </div>
        <div className="apply-list-table">
          <Table
            dataSource={this.state.applyList}
            columns={this.state.applyColumns}
            loading={this.state.tableLoading}
            pagination={{  //分页
              total: this.state.count, //数据总数量
              pageSize: this.state.pageSize,  //显示几条一页
              defaultPageSize: this.state.pageSize, //默认显示几条一页
              showSizeChanger: false,  //是否显示可以设置几条一页的选项

              onChange(current) {  //点击改变页数的选项时调用函数，current:将要跳转的页数
                console.log(current)
                // this.gotoThispage(current, this.state.queryInfo.pageSize);
                self.state.pageNum = current;
                self.getApplyList(self.state.pageSize, self.state.pageNum)
              },
              showTotal() {  //设置显示一共几条数据
                return '共 ' + this.total + ' 条数据';
              }
            }}>
          </Table>
        </div>
        </div>
        </div>
      </div>
    )
  }
}
