import React, { Component } from 'react'
import { Tabs } from 'antd'
import './index.less'
import { Ajax } from '../../util/ajax';
import eventBus from '../../eventBus/index'
import LoginForm from '../loginForm'
import { Link } from 'react-router-dom';
const { TabPane } = Tabs

export default class Login extends Component {
  constructor(props) {
    super(props)
    this.state = {
      wechatState: Math.ceil(Math.random()*1000),
    }
  }

  componentDidMount() {
    eventBus.addListener('userLogined', () => {
      this.props.history.push({pathname: '/'})
    })
  }

  handleTabChange(key) {
    if (key === '1')
    eventBus.emit('loginModalClosed')
    console.log(key)
  }
  
  render() {
    let callbackUrl = `https://www.95wai.com/login/load`
    if (window.location.hostname !== 'www.95wai.com') {
      callbackUrl = `https://www.95wai.com/login/test/load`
    }
    return (
      <div className="login-container">
        <div className="modal-content">
          <div className="modal-title">登录账号</div>
          <Tabs className="login-tabs" defaultActiveKey="1" onChange={this.handleTabChange.bind(this)}>
            <TabPane tab="微信登录" key="1">
            <div className="auth-qrcode">
              <iframe className="nlogin_iframe"
                title="navigation"
                frameBorder="0"
                sandbox="allow-scripts allow-same-origin allow-top-navigation"
                scrolling="no"
                src={`https://open.weixin.qq.com/connect/qrconnect?appid=wxd50690f3eac0ade3&redirect_uri=${encodeURI(callbackUrl)}&response_type=code&scope=snsapi_login&state=${this.state.wechatState}&href=data:text/css;base64,LmltcG93ZXJCb3ggLnFyY29kZSB7d2lkdGg6IDIwMHB4O30NCi5pbXBvd2VyQm94IC50aXRsZSB7ZGlzcGxheTogbm9uZTt9DQouaW1wb3dlckJveCAuaW5mbyB7ZGlzcGxheTogbm9uZTt9DQouc3RhdHVzX2ljb24ge2Rpc3BsYXk6IG5vbmV9DQouaW1wb3dlckJveCAuc3RhdHVzIHt0ZXh0LWFsaWduOiBjZW50ZXI7fSA#wechat_redirect`}></iframe>
              </div>
            </TabPane>
            <TabPane tab="密码登录" key="2" className="login-form-content">
              <LoginForm></LoginForm>
              <div className="modal-tip"><Link to={{pathname: '/bind/phone', state: {type: 'forgetPassword'}}}>忘记密码?</Link></div>
            </TabPane>
          </Tabs>
        </div>
      </div>
    )
  }
}
