import React, { Component } from 'react'
import './index.less'
import RegisterFrom from '../registerForm'

export default class BindPhone extends Component {
  constructor(props) {
    super(props)
    let state = this.props.location.state;
    this.state = {
      type: 'bindPhone'
    }
    if (state) {
      this.state.type = state.type
    }
  }

  componentDidMount() {
    console.log('unionid', this.state.unionid)
  }

  render() {
    return (
      <div className="bind-container">
        <div className="bind-content">
          <div className="bind-title">
            {this.state.type === 'bindPhone' && <span>绑定手机号</span>}
            {this.state.type === 'forgetPassword' && <span>重置密码</span>}
          </div>
          <RegisterFrom history={this.props.history} type={this.state.type}></RegisterFrom>
        </div>
      </div>
    )
  }
}
