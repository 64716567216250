import React, {Component} from 'react'
import { Table, message, Breadcrumb, Modal, Button, Row, Col } from 'antd'
import { Ajax } from '../../util/ajax'
import { Link } from 'react-router-dom'
import './index.less'

export default class ActivityApplyManage extends Component {
  constructor(props) {
    super(props)
    let state = this.props.location.state;
    this.state = {
      activityId: state.activityId,
      applyList: [],
      pageNum: 1,
      pageSize: 10,
      condition: '',
      count: 0,
      tableLoading: false,
      applyInfoVisible: false,
      fieldList: [],
      currentApply: {},
      applyColumns: [
        {
          title: '头像',
          dataIndex: 'avatar_url',
          width: 80,
          render: (record) => {
            return <img src={record} alt="" style={{ width: '40px', height: '40px' }} />
          }
        }, {
          title: '昵称',
          dataIndex: 'nickname',
          width: 150
        }, {
          title: '报名时间',
          dataIndex: 'apply_time',
          width: 200
        }, {
          title: '手机号',
          dataIndex: 'phone',
          width: 140
        }, {
          title: '选项',
          dataIndex: 'type_name',
          width: 120
        }, {
          title: '票价',
          dataIndex: 'apply_price',
          render: (text,record) =>{
            if(record.apply_price >0){
              return <span>￥{record.apply_price}</span>
            }
            if(record.apply_price === 0){
              return <span>免费</span>
            }
          }
        }, {
          title: '报名人数',
          dataIndex: 'apply_people_number'
        }, {
          title: '签到',
          render: (text, record) => {
            if (record.check_in) {
              return <span>已签到</span>
            } else {
              return <span>--</span>
            }
          }
        }, {
          title: '问卷信息',
          dataIndex: 'applyInfo',
          width: 120,
          render: (text, record) => {
            if (record.apply_info) {
              return <Button type="link" style={{ padding: "0px" }} onClick={this.viewApplyInfo.bind(this, record)}>查看</Button>
            } else {
              return <span>--</span>
            }
          },
        }
      ],
      signInList: [],
      signInColumns: [
        {
          title: '头像',
          dataIndex: 'avatarUrl',
          width: 150,
          render: (record) => {
            return <img src={record} alt="" style={{ width: '40px', height: '40px' }} />
          }
        }, {
          title: '昵称',
          dataIndex: 'nickname',
        }, {
          title: '时间',
          dataIndex: 'signInTime'
        }
      ]
    }
  }
  componentDidMount() {
    this.getApplyList(this.state.pageSize, this.state.pageNum)
  }
  getApplyList(pageSize, pageNum) {
    this.setState({
      tableLoading: true
    })
    let url = `/apply/getApplyPeopleList?activityId=${this.state.activityId}&pageSize=${pageSize}&pageNum=${pageNum}`
    if (this.state.condition) {
      url += '&condition=' + this.state.condition
    }
    Ajax.POST(url).then( r =>{
      if(r.code === 200){
        let myData = r.data.list
        let count = r.data.total
        myData.forEach((item, index, arr) => {
            arr[index].key = index
            if (item.fieldList && item.fieldList.length > 0) {
              let fieldList = item.fieldList
              fieldList.forEach((field, i)=>{
                field['varcharField'] =item['varchar_field'+ (i +1)]
              })
              arr[index].fieldList = fieldList
            }
        });
        this.setState({
          applyList: myData,
          count: count,
          tableLoading: false
        })
      }else{
        message.error(r.message)
      }
    })
  }
  hideApplyInfoModalVisible() {
    this.setState({
      applyInfoVisible: false
    })
  }
  viewApplyInfo(activityApply) {
    this.setState({
      fieldList: activityApply.fieldList,
      currentApply: activityApply,
      applyInfoVisible: true
    })
  }
  render() {
    var self = this
    return (
      <div className="my-activity-apply-manage">
        <div className="apply-manage-content">
          <Breadcrumb className="apply-manage-breadcrumb">
            <Breadcrumb.Item>
              <Link to={{pathname: '/activity/apply/manage'}}>报名列表</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item><span style={{ color: "#E35F4B" }}>报名人员</span></Breadcrumb.Item>
          </Breadcrumb>
          <div className="apply-manage-card">
            <div className="apply-list-table">
              <Table
                dataSource={this.state.applyList}
                columns={this.state.applyColumns}
                loading={this.state.tableLoading}
                pagination={{  //分页
                  total: this.state.count, //数据总数量
                  pageSize: this.state.pageSize,  //显示几条一页
                  defaultPageSize: this.state.pageSize, //默认显示几条一页
                  showSizeChanger: false,  //是否显示可以设置几条一页的选项

                  onChange(current) {  //点击改变页数的选项时调用函数，current:将要跳转的页数
                    console.log(current)
                    // this.gotoThispage(current, this.state.queryInfo.pageSize);
                    self.state.pageNum = current;
                    self.getApplyList(self.state.pageSize, self.state.pageNum)
                  },
                  showTotal() {  //设置显示一共几条数据
                    return '共 ' + this.total + ' 条数据';
                  }
                }}>
              </Table>
            </div>
          </div>
        </div>
        <Modal
          title="问卷信息"
          visible={this.state.applyInfoVisible}
          onCancel={this.hideApplyInfoModalVisible.bind(this)}
          footer={null}
          width={660}>
            { this.state.fieldList.length > 0 && this.state.fieldList.map((item, index) => {
              return <div key={index} className="activity-apply-info-detail">
                <Row gutter={20}>
                  <Col span={7} className="field-name">{item.fieldName}</Col>
                  <Col span={14}>{item.varcharField}</Col>
                </Row>
                </div>}) }
        </Modal>
      </div>
    )
  }
}
