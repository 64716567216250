import React, { Component } from 'react'
import activities from '../../filter/activities'
import './index.less'
import { Table, Row, Col } from 'antd'

export default class ActivityPreview extends Component {
  constructor(props) {
    super(props)
    this.state = {
      ActObj : this.props.ActObj,
      typeList: this.props.typeList,
      columns: [
        {
          title: '选项',
          dataIndex: 'typeName'
        }, {
          title: '费用',
          dataIndex: 'typePrice'
        }, {
          title: '库存',
          dataIndex: 'typeInitStock'
        }, {
          title: '截止时间',
          dataIndex: 'typeDeadline'
        }, {
          title: '鸽子费',
          dataIndex: 'typeDoveFee',
          render: (text, record) => {
            return (
              <div>
                {record.typeDoveFee === 1 && <span>是</span>}
                {record.typeDoveFee === 0 && <span>否</span>}
              </div>
            )
          }
        }
      ]
    }
  }

  componentDidMount() {}
  renderModalItem() {
    let data = this.state.ActObj.typeParams
    if(data){
      this.state.ActObj.typeParams.map((item, index) => {
        data[index].key = index
      })
      return (
        <Table
          dataSource={data}
          columns={this.state.columns}
          pagination = {false}
          bordered
        ></Table>
      )
    } else {
      return <div>暂无规格</div>
    }
  }
  render() {
    const ActObj = this.state.ActObj
    return (
      <div className="activity-preview">
        <Row gutter={20} key="1">
          <Col span={6} className="title">标题</Col>
          <Col span={17}>{ActObj.activityTitle}</Col>
        </Row>
        <Row gutter={20} key="2">
          <Col span={6} className="title">图片</Col>
          <Col span={17}>
            <div className="activity-img"><img className="actImg" src={ActObj.activityImgUrl} alt="" /></div>
          </Col>
        </Row>
        <Row gutter={20} key="3">
          <Col span={6} className="title">日期</Col>
          <Col span={17}>{ActObj.activityStartTime} 至 {ActObj.activityEndTime}</Col>
        </Row>
        <Row gutter={20} key="4">
          <Col span={6} className="title">地点</Col>
          <Col span={17}>
            <div>
              {ActObj.activitySiteSort === 0 && <span>线上</span>}
              {ActObj.activitySiteSort === 1 && <span>{ActObj.address.activityAddress}</span>}
            </div>
          </Col>
        </Row>
        <Row gutter={20} key="5">
          <Col span={6} className="title">活动规格</Col>
          <Col span={17}>
            <div className="activity-sku">
              {this.renderModalItem()}
            </div>
          </Col>
        </Row>
        <Row gutter={20} key="6">
          <Col span={6} className="title">希望报名者参与问卷填写</Col>
          <Col span={17} className="activity-apply-info">
            {ActObj.fieldParams.map((item, index) => {
              return <div key={index}>
                <div>
                  <span style={{ marginRight: 50 }}>{item.fieldName}</span>
                </div>
              </div>
            })}
          </Col>
        </Row>
        <Row gutter={20} key="7">
          <Col span={6} className="title">活动内容</Col>
          <Col span={17}>
            <div className="activity-description">
              <div dangerouslySetInnerHTML={{__html:ActObj.activityDescription}}></div>
            </div>
          </Col>
        </Row>
        <Row gutter={20} key="8">
          <Col span={6} className="title">活动状态</Col>
          <Col span={17}>{activities.status[ActObj.status]}</Col>
        </Row>
      </div>
    )
  }
}