import React, { Component } from 'react';
import { Table, Input, Popconfirm, Select, Modal, message, Button, Breadcrumb, Tabs } from 'antd'
import { Ajax } from '../../util/ajax'
import { Link } from 'react-router-dom'
import './index.less';
import TextArea from 'antd/lib/input/TextArea';
import eventBus from '../../eventBus';
import searchIcon from '../../asserts/images/Group4@2x.png'
const Search = Input.Search;
const Option = Select.Option;
const { TabPane } = Tabs

export default class QuitApplyManage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      visible: false,
      visible2: false,
      tableLoading: false,
      pageSize: 10,
      pageNum: 1,
      myData: [],
      noPassId: '',
      searchState: '-1',
      noPassText: '',
      reasonTit: '',
      columns: [
        {
          title: '活动编号',
          dataIndex: 'activityNumber',
          width: 120
        }, {
          title: '活动名称',
          dataIndex: 'activityTitle',
          width: 90
        }, {
          title: '头像',
          dataIndex: 'avatarUrl',
          width: 50,
          render: (record) => {
            return <img src={record} alt="" style={{ width: '40px', height: '40px' }} />
          }
        }, {
          title: '用户',
          dataIndex: 'nickname',
          width: 100
        }, {
          title: '手机号',
          dataIndex: 'phone',
          width: 140
        },{
          title: '申请时间',
          dataIndex: 'createTime',
          width: 120
        }, {
          title: '退票金额',
          dataIndex: 'applyPrice'
        }, {
          title: '退款状态',
          dataIndex: 'refundStatus',
          render: (text, record) => {
            switch (record.refundStatus) {
              case 0:
                return <span>未退款</span>
              case 1:
                return <span>退款成功</span>
              case 2:
                return <span>退款异常 </span>
              case 3:
                return <span>退款关闭</span>
              default:
            }
          }
        }, {
          title: '状态',
          dataIndex: 'checkStatus',
          render: (text, record) => {
            switch (record.checkStatus) {
              case (0):
                return <span style={{ color: '#FFBF24' }}>未处理</span>
              case (1):
                  return <span style={{ color: '#41C658' }}>已通过</span>
              case (2):
                return <span style={{ color: '#BA1212' }}>不通过</span>
              default:
            }
          }
        }, {
          title: '操作',
          dataIndex: 'Status',
          render: (text, record) => {
            switch (record.Status) {
              case (2):
                return <Button type="link" onClick={this.showModal.bind(this, record.key)} style={{ padding: 0 }}>查看原因</Button>
              case (1):
                return <span style={{ color: "#2a8ff6" }}>--</span>
              case (0):
                return <div>
                  <Popconfirm title="确定通过申请吗？" onConfirm={this.pass.bind(this, record.key)} >
                    <Button type="link">通过</Button>
                  </Popconfirm><br/>
                  <Popconfirm title="确定拒绝申请吗？" onConfirm={this.noPass.bind(this, record.key)} >
                    <Button type="link">
                      拒绝
                    </Button>
                  </Popconfirm>
                    </div>
              default:
            }
          },
        }
      ]
    }
  }
  pass(val) {
    this.checkAudit(1, val, '', 1)
  }
  noPass(val) {
    console.log(val)
    this.setState({
      noPassId: val
    })
    this.showModal2()
  }
  reason(checkId) {
    Ajax.POST('/applyCheck/getReason?checkId=' + checkId).then(r => {
      if (r.code === 200) {
        this.setState({
          reasonTit: r.data
        })
      } else {
        message.error(r.message)
      }
    })
  }
  checkAudit(type, checkId, checkReason, checkStatus) {
    if (type === 1) {  //通过
      Ajax.POST('/applyCheck/checkApply?checkId=' + checkId + '&checkStatus=' + checkStatus).then(r => {
        if (r.code === 200) {
          message.success('通过该订单成功')
          eventBus.emit('dealQuitApply')
          this.getData(this.state.pageSize, this.state.pageNum, -1)
        } else {
          message.error(r.message)
        }
      })
    } else if (type === 2) { //不通过
      Ajax.POST('/applyCheck/checkApply?checkId=' + checkId + '&checkStatus=' + checkStatus + '&checkReason=' + checkReason).then(r => {
        if (r.code === 200) {
          eventBus.emit('dealQuitApply')
          message.success('不通过该订单成功')
          this.getData(this.state.pageSize, this.state.pageNum, -1)
        } else {
          message.error(r.message)
        }
      })
    }
  }
  showModal(val) {
    // console.log(val)
    this.setState({
      visible: true,
    });
    this.reason(val)
  }
  hideModal = () => {
    this.setState({
      visible: false,
    });
  }
  showModal2() {
    this.setState({
      visible2: true,
    });
  }
  hideModal2 = () => {
    this.setState({
      visible2: false,
    });
  }
  componentDidMount() {
    this.getData(this.state.pageSize, this.state.pageNum, -1)
  }
  getData(pageSize, pageNum, status) {
    var status = this.state.searchState
    this.setState({
      tableLoading: true,
    })

    let url = `/applyCheck/list?pageSize=${pageSize}&pageNum=${pageNum}&status=${status}`
    if (this.state.condition) {
      url += "&condition=" + this.state.condition
    }
    Ajax.POST(url).then(r => {
      if (r.code === 200) {
        console.log(r)
        let myData = r.data.list,
          count = r.data.total;
        myData.forEach((item, index, arr) => {
          arr[index].key = item.checkId
          arr[index].Status = item.checkStatus
        });
        this.setState({
          myData: myData,
          count: count,
          tableLoading: false
        })
      } else {
        message.error(r.message)
      }
    })
  }
  searchVal(val) {
    this.state.pageNum = 1;
    if (val) {
      this.state.condition = val
      this.getData(this.state.pageSize, this.state.pageNum, this.state.searchState)
    } else {
      this.state.condition = ""
      this.getData(this.state.pageSize, this.state.pageNum, this.state.searchState)
      // message.info('请输入搜索关键词')
    }
  }

  searchFun(condition, status) {
      this.setState({
        tableLoading: true,
      })
      Ajax.POST('/applyCheck/list?condition=' + condition + '&status=' + status).then(r => {
          if (r.code === 200) {
              console.log(r)
              let myData = r.data.list,
                  count = r.data.total;
              myData.forEach((item, index, arr) => {
                  arr[index].key = item.checkId
                  arr[index].checkStatus = item.checkStatus
              });
              this.setState({
                  myData: myData,
                  count: count,
                  tableLoading: false,
              })

          } else {
              message.error(r.message)
          }
      })
  }
  noPassFun = (e) => {
      this.setState({
          noPassText: e.target.value
      })
  }
  noPassSubmit = (e) => {
      this.setState({
          visible2: false,
      });
      this.checkAudit(2, this.state.noPassId, this.state.noPassText, 2)
  }
  findOrder(val) {
      this.state.searchState = val
      this.getData(this.state.pageSize, this.state.pageNum, val)
  }
  handleTabChange(value) {
    this.props.history.push({pathname: '/activity/apply/manage', state: {activityId: this.state.activityId}})
  }
  render() {
    var self = this;
    return (
      <div className="quit-manage">
        <div className="quit-manage-content">
          <div className="quit-manage-card">
            <div className="card-title">报名管理</div>
            <Tabs onChange={this.handleTabChange.bind(this)} activeKey="2" style={{ marginTop: 20 }}>
              <TabPane tab="报名列表" key="1">
              </TabPane>
              <TabPane tab="取消报名" key="2">
              </TabPane>
            </Tabs>
            <div className="quit-apply-list-search">
              <Select onChange={this.findOrder.bind(this)} value={this.state.searchState} defaultValue="-1" className="search-select" >
                <Option value="-1">全部</Option>
                <Option value="0">未处理</Option>
                <Option value="1">已通过</Option>
                <Option value="2">不通过</Option>
              </Select>
              <Search
                className="search-input"
                placeholder="请输入"
                enterButton="搜索 "
                onSearch={value => this.searchVal(value)}
              />
          </div>
          <div className="quit-apply-list-table">
            <Table dataSource={this.state.myData}
              columns={this.state.columns}
              loading={this.state.tableLoading}
              pagination={{  //分页
                current:this.state.pageNum,
                total: this.state.count, //数据总数量
                pageSize: this.state.pageSize,  //显示几条一页
                defaultPageSize: this.state.pageSize, //默认显示几条一页
                showSizeChanger: false,  //是否显示可以设置几条一页的选项

                onChange(current) {  //点击改变页数的选项时调用函数，current:将要跳转的页数
                  console.log(current)
                  // this.gotoThispage(current, this.state.queryInfo.pageSize);
                  self.state.pageNum = current;
                  self.getData(self.state.pageSize, self.state.pageNum, self.state.searchState)
                },
                showTotal() {  //设置显示一共几条数据
                  return '共 ' + this.total + ' 条数据';
                }
              }}
            ></Table>
          </div>
        </div>
        <Modal
          title="不通过原因"
          visible={this.state.visible}
          onOk={this.hideModal}
          onCancel={this.hideModal}
          okText="确认"
          cancelText="取消">
          <p>{this.state.reasonTit}</p>
        </Modal>
        <Modal
          title="请输入不通过原因"
          visible={this.state.visible2}
          onOk={this.noPassSubmit}
          onCancel={this.hideModal2}
          okText="确认"
          cancelText="取消">
          <TextArea value={this.state.noPassText} onChange={this.noPassFun} placeholder="请输入不通过原因" style={{ height: '100px' }}></TextArea>
        </Modal>
        </div>
      </div>
    )
  }
}
