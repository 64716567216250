import React, { Component } from 'react'
import { Form, DatePicker, Input, Upload, Button, Modal, Icon, Radio, Select, message, InputNumber, Switch, Checkbox, Tooltip, Breadcrumb } from 'antd'
import moment from 'moment';
import BraftEditor from 'braft-editor'
import { Link } from 'react-router-dom'
import { Ajax } from '../../util/ajax'
import './index.less'
import 'braft-editor/dist/index.css'
import uploadImg from "../../util/upload"
import activityFilter from '../../filter/activities'

import ActivityPreview from "../ActivityPreview"
const RadioGroup = Radio.Group;
const Option = Select.Option;
let timeout;
let currentValue;
function fetchCity(value, callback) {
  if (timeout) {
    clearTimeout(timeout)
    timeout = null
  }
  currentValue = value
  function fake() {
    Ajax.POST(`/city/search?condition=${value}`).then(r => {
      if (currentValue === value) {
        const data = [];
        r.data.forEach(d => {
          data.push({
            value: d.id,
            text: d.name
          })
        })
        callback(data)
      }
    })
  }
  timeout = setTimeout(fake, 300)
}

class ActivityEdit extends Component {
  constructor(props) {
    super(props)
    let state = this.props.location.state;
    this.state = {
      previewVisible: false,
      previewImage: '',
      fileList: [],
      addTit: '确认添加',
      editorState1: null,
      editorState2: null,
      editorState3: null,
      outputHtml1: '',
      outputHtml2: '',
      outputHtml3: '',
      optionsList: [],
      typeList: [],
      activityTitle: '',
      activitySiteSort: 0,
      activityStartTime: moment().format('YYYY-MM-DD HH:mm'),
      activityEndTime: moment().format('YYYY-MM-DD HH:mm'),
      activityMinPeople: 1,
      classList: [],
      classVal: '',
      takerSort: 0,
      groupId: '',
      groupSubhead: '',
      groupTitle: '',
      activityApplySort: 0,
      activityDescription: BraftEditor.createEditorState(null),
      activityFlow: BraftEditor.createEditorState(null),
      activityApplyNotice: BraftEditor.createEditorState(null),
      city: '',
      cities: [],
      activityFee: 0,
      status: 'reviewing',
      applyInfo: 0,
      fieldList: [],
      activityCityId: 1,
      showWithdrawDescription: false,
      isSetDoveFee: 0,
      saveActivityEndTime: moment().format('YYYY-MM-DD HH:mm')
    }

    if (state) {
      this.state.isChange = true
      this.isLiving = false
      this.state.activityId = state.activityId
    }
  }
  componentDidMount() {
    if (localStorage.getItem("isAllowPublish") && localStorage.getItem("isAllowPublish") == 0) {
      message.warning("你已被禁止发起活动，如有疑问，请联系管理员")
      this.props.history.push({pathname: '/'})
    }
    if (this.state.isChange) {
      Ajax.POST('/activity/get?activityId=' + this.state.activityId).then(r => {
        if (r.code === 200) {
          this.isLiving = true

          r.data.activityDescription = BraftEditor.createEditorState(r.data.activityDescription)
          r.data.addTit = '确认修改'
          r.data.fileList = [{
            uid: '-1',
            status: 'done',
            response: r.data.activityImgUrl,
            thumbUrl: r.data.activityImgUrl
          }]
          r.data.saveActivityEndTime = r.data.activityEndTime
          this.setState({ ...r.data })
          console.log('thisstats', this.state)
        }
      })
    }
    else {
      this.isLiving = true
      this.addItem()
    }

    // 3秒后更改编辑器内容
    // setTimeout(this.setEditorContentAsync, 2000)
    this.getClass()
    this.getCities()
  }
  componentWillUnmount() {
    this.isLiving = false
  }
  initTypeList() {
    var typeNames = ["typeName", "typePrice", "typeInitStock", "typeDeadline"];
  }

  customRequest = (detail) => {
    uploadImg(detail)
  }
  handleEditorChange = (editorState) => {
    var result = editorState.toHTML()
    if (result) {
      if (/^<p><\/p>$/.test(result)) {
        result = ""
      }

    }
    return result
  }
  setEditorContentAsync = () => {
    this.isLivinig && this.setState({
      activityApplyNotice: BraftEditor.createEditorState("11313133"),
    })
  }
  getCities() {
    Ajax.POST(`/activity/hot/list?activityCityId=${this.state.activityCityId}`).then( r => {
      if (r.code === 200) {
        const data = [];
        r.data.forEach(d => {
          data.push({
            value: d.id,
            text: d.name
          })
        })
        this.setState({
          cities: data
        })
      }
    })
  }
  classChange(val) {
    let myArr = val.split('_')
    console.log(myArr)
    this.setState({
      classVal: myArr[1],
      classId: myArr[0]
    })
  }
  submitContent = async () => {
    // 在编辑器获得焦点时按下ctrl+s会执行此方法
    // 编辑器内容提交到服务端之前，可直接调用editorState.toHTML()来获取HTML格式的内容
    const htmlContent = this.state.editorState1.toHTML()
    console.log(htmlContent)
    // const result = await saveEditorContent(htmlContent)
  }

  isEmpty(value) {
    if (value === undefined || value === null || value === '') {
      return true
    }

    return false
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      console.log(values)
      if (!err) {
        if (this.state.fileList.length == 0) {
          message.error('照片不能为空')
          return
        }

        if (!this.state.fileList[0].response) {
          message.error('照片不能为空')
          return
        }
        let flag = 1
        for (let i = 0; i < this.state.typeList.length; i ++) {
          if (this.isEmpty(this.state.typeList[i].typeName) || this.state.typeList[i].typeName.trim() === '') {
            message.error('规格选项不能为空')
            flag = 0
            break
          }
          if (this.isEmpty(this.state.typeList[i].typePrice)) {
            message.error('规格费用不能为空')
            flag = 0
            break
          }
          if (this.isEmpty(this.state.typeList[i].typeInitStock)) {
            message.error('规格库存不能为空')
            flag = 0
            break
          }
          if (moment(this.state.typeList[i].typeDeadline).isSameOrBefore(moment(moment().format('YYYY-MM-DD HH:mm')))) {
            message.error('价格截止时间不能早于当前时间')
            flag = 0
            break
          }
        }
        if (flag === 0) {
          return
        }
        for (let j = 0; j < this.state.fieldList.length; j ++) {
          if (this.state.fieldList[j].fieldName.trim() === '' || this.isEmpty(this.state.fieldList[j].fieldName)) {
            message.error('信息标题不能为空')
            flag = 0
            break
          } else if (this.state.fieldList[j].fieldName.length > 20) {
            message.error('信息标题不能超过20个字')
            flag = 0
            break
          }
        }
        if (flag === 0) {
          return
        }
        this.handleData(values)
        console.log('Received values of form: ', values);

        // if(values.activityApplySort === "0" || values.activityApplySort === 0){
        //   if(!values.typeParams || values.typeParams.length <= 0){
        //     message.error('规格不能为空')
        //     return
        //   }
        // }
        if (values.activityFee === "1" || values.activityFee === 1) {
          if(!values.typeParams || values.typeParams.length <= 0){
            message.error('规格不能为空')
            return
          }
        }

        let url = this.state.isChange ? `/activity/updateActivity?type=user` : "/activity/addActivity"

        Ajax.POST(url, values).then((r) => {
          if (r.code === 200) {
            if (this.state.isChange) {
              message.success('修改成功')
            } else {
              message.success('添加成功')
            }

            setTimeout(() => {
              if (this.state.isChange) {
                this.props.history.go(-1)
              } else (
                this.props.history.push({pathname: '/my/activity/manage'})
              )
            })
          }
        })
      } else {
        for (var p in err) {
          var errs = err[p].errors;
          for (var i = 0; i < errs.length; i++)
            message.error(errs[i].message)
        }
      }
    });
  }
  handleChange = (info => this.setState({ fileList: info.fileList }))

  getClass() {
    Ajax.POST('/activitySort/getAllActivitySort').then(r => {
      if (r.code === 200) {
        this.setState({
          classList: r.data
        })
      } else {
        message.error(r.message)
      }
    })
  }
  titleIpt(e) {
    this.setState({
      activityTitle: e.target.value
    })
  }

  timechange = (time) => {
    return moment(time).format('YYYY-MM-DD HH:mm')
  }

  changeAddress = (e) => {
    this.setState({
      activitySiteSort: e.target.value
    })
  }
  changeStatus = (e) => {
    this.setState({
      status: e.target.value
    })
  }
  changeApplication = (e) => {
    this.setState({
      activityFee: e.target.value
    })
  }
  applySortChange = (e) => {
    this.setState({
      activityApplySort: e.target.value
    })
  }
  optionsChange(val) {
    let myArr = val.split('_')
    this.setState({
      takerId: myArr[0],
      defaultVal: myArr[1]
    })
  }
  changePeople = (e) => {
    if (!/^\d+/.test(e.target.value)) {
      message.info('请输入大于0的数字')
    } else if (!e.target.value) {
      message.info('请输入大于0的数字')
    } else if (!e.target.value || e.target.value <= 0 || e.target.value.slice(0, 1) === '0') {
      message.info('请输入大于0的数字')
    } else {
    }

    this.setState({
      activityMinPeople: e.target.value
    })
  }

  addItem = (e) => {
    let typeList = this.state.typeList || []
    typeList.push({ typeInitStock: '', typeName: '', typePrice: '', typeDeadline: this.props.form.getFieldValue('activityEndTime').format('YYYY-MM-DD HH:mm'), typeDoveFee: 0 })
    this.setState({
      typeList: typeList
    })
  }
  delItem = (index) => {
    let typeList = this.state.typeList
    // document.getElementById(delIndex).remove();
    // console.log(typeList.splice(delIndex,1))
    typeList.splice(index, 1)
    if (typeList.length === 0) {
      message.info('没有规格了呢')
    }
    this.setState({
      typeList: typeList,
    })
  }
  delFieldItem(index) {
    let fieldList = this.state.fieldList
    fieldList.splice(index, 1)
    if (fieldList.length === 0) {
      message.info('没有需要填写的报名信息了呢')
    }
    this.setState({ fieldList: fieldList })
  }
  typeNameIpt = (e) => {
    let Index = e.target.getAttribute('data-index')
    let typeId = e.target.getAttribute('data-typeid')
    let typeList = this.state.typeList
    typeList[Index].typeName = e.target.value
    typeList[Index].typeId = typeId
    this.setState({
      typeList: typeList
    })
  }
  typePriceIpt = (e) => {
    let Index = e.target.getAttribute('data-index')
    let typeId = e.target.getAttribute('data-typeid')
    let typeList = this.state.typeList
    typeList[Index].typePrice = e.target.value
    typeList[Index].typeId = typeId
    this.setState({
      typeList: typeList
    })
  }
  typeInitStockIpt = (e) => {
    let Index = e.target.getAttribute('data-index')
    let typeId = e.target.getAttribute('data-typeid')
    let typeList = this.state.typeList
    typeList[Index].typeInitStock = e.target.value
    typeList[Index].typeId = typeId
    this.setState({
      typeList: typeList
    })
  }
  addressIpt = (e) => {
    this.setState({
      activityAddress: e.target.value
    })
  }
  addFieldItem = (e) => {
    let fieldList = this.state.fieldList || []
    fieldList.push({ fieldName: ''})
    this.setState({
      fieldList: fieldList
    })
  }

  preview = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      console.log(values)

      if (!err) {
        if (this.state.fileList.length == 0) {
          message.error('照片不能为空')
          return
        }

        if (!this.state.fileList[0].response) {
          message.error('照片不能为空')
          return
        }

        this.handleData(values)

        if(values.activityFee === "1" || values.activityFee === 1) {
          if(!values.typeParams || values.typeParams.length <= 0){
            message.error('规格不能为空')
            return
          }
        }

        if (values.applyInfo === "1" || values.applyInfo === 1) {
          if (!values.fieldParams || values.fieldParams.length <= 0) {
            message.error("填写信息字段名称不能为空")
            return
          }
        }

        values.typeList = values.typeParams
        this.setState({
          showDataPreview: true,
          previewValues: values
        })
      }
    });
  }


  handleModelOk = () => {
    this.setState({
      showDataPreview: false,
    })
  }

  renderPreview() {
    if (this.state.showDataPreview) {
      return (
        <Modal width="1140px"
          title="预览"
          visible={this.state.showDataPreview}
          onOk={this.handleModelOk}
          onCancel={this.handleModelOk}
        >
          <ActivityPreview ActObj={this.state.previewValues}></ActivityPreview>
        </Modal>
      )
    }
  }

  handleData(values) {
    var typeParams = this.state.typeList
    var fieldParams = this.state.fieldList
    for (let p in values) {
      if (moment.isMoment(values[p])) {
        values[p] = values[p].format('YYYY-MM-DD HH:mm')
      }

      if (p === "activityImgUrl") {
        values[p] = values[p][0].response
      }

      if (p === "applyInfo") {
        values[p] = values[p] === true ? 1 : 0
      }

      if (p === "isSetDoveFee") {
        values[p] = values[p] ? 1 : 0
      }

      if (p === "activityAddress") {
        values["address"] = {
          activityAddress: values['activitySiteSort'] === 1 ? values[p] : ''
        }
        delete values["activityAddress"]
      }

      if (p === 'activityCityId') {
        values[p] = values['activitySiteSort'] === 1 ? values[p] : 0
      }

      if (p === 'activityDescription' || p === 'activityFlow' || p === 'activityApplyNotice') {
        if (typeof values[p] !== 'string') {
          values[p] = values[p].toHTML()
        }
      }
    }

    values.activityImgUrl = this.state.fileList[0].response
    typeParams.map((item, index, arr) => {
      arr[index].typeDoveFee = this.state.isSetDoveFee ? 1 : 0
    })
    values.typeParams = typeParams
    values.fieldParams = fieldParams

    if (this.state.activityId) {
      values.activityId = this.state.activityId
    }
    return values
  }

  handlePreview = (file) => {
    this.setState({
      previewImage: file.url || file.thumbUrl,
      previewVisible: true,
    });
  }

  handleCancel = () => this.setState({ previewVisible: false })

  handleSearchCity(value) {
    if (value) {
      fetchCity(value, data => this.setState({ cities: data }))
    } else {
      this.setState({ cities: [] });
    }
  }
  handleChangeCity(e) {
    console.log(e)
   // this.setState({ city })
  }
  handleChangeApplyInfo(value) {
    let applyInfo = value === true ? 1 : 0
    this.setState({
      applyInfo: applyInfo
    })
    if (value) {
      this.addFieldItem()
    } else {
      this.setState({
        fieldList: []
      })
    }
  }
  renderActivityAddress() {
    const { getFieldDecorator } = this.props.form;
    const cityOptions = this.state.cities.map(d => <Option key={d.value} value={d.value}>{d.text}</Option>);
    const formMiddleLayout = {
      labelCol: { span: 7 },
      wrapperCol: { span: 17, offset: 7 },
    };
    if (this.state.activitySiteSort == 1) {

      return (
        <Form.Item {...formMiddleLayout}>
          <div className="activity-address">
            <div>城市：</div>
            <div className="activity-address-city">{getFieldDecorator('activityCityId', {
              initialValue: this.state.activityCityId > 0 ? this.state.activityCityId : undefined,
              rules: [{
                required: true, message: '活动城市不能为空',
              }]
            })(
              <Select
                style={{ width: 120, marginRight: 20 }}
                showSearch
                placeholder="选择城市"
                defaultActiveFirstOption={false}
                showArrow={false}
                filterOption={false}
                onSearch={this.handleSearchCity.bind(this)}
                onChange={this.handleChangeCity.bind(this)}
                notFoundContent={null}
              >
                {cityOptions}
              </Select>
            )}</div>
            <div>详细地址：</div>
            <div className="activity-address-detail">
              {getFieldDecorator('activityAddress', {
                  initialValue: this.state.activityAddress,
                  rules: [{
                    required: true, message: '活动地址不能为空',
                  }, {
                    max: 50,
                    message: '活动地址不能超过50个字'
                  }]
                })(
                  <Input style={{ width: 300 }}/>
                )
              }
            </div>
          </div>
          <div className="tips">提示：可根据拼音和汉字搜索城市；支持线下活动现场扫码签到；请发起人在小程序“我发布的活动”中扫码验票。用户报名成功后，可在“我报名的活动”中查看签到码。</div>
        </Form.Item>
      )
    }
  }
  handleChangeFieldName = (event, index) => {
    let fieldList = this.state.fieldList
    fieldList[index].fieldName = event.target.value
    this.setState({
      fieldList: fieldList
    })
  }
  renderActivityApplyCustomField() {
    const { getFieldDecorator } = this.props.form
    const formMiddleLayout = {
      labelCol: { span: 7 },
      wrapperCol: { span: 17, offset: 7 },
    };
    if (this.state.applyInfo == 1) {
      return (
        <div>
          <Form.Item {...formMiddleLayout}>
            <div className="apply-info">
              <div className="apply-info-title">
                <div>信息标题</div>
              </div>
              <div className="divider" style={{ width: 400 }}></div>
              <div className="apply-info-body">
                {this.state.fieldList &&
                  this.state.fieldList.map((data, index) => {
                    return (
                      <div id={index} className="apply-info-item" key={index}>
                        <div style={{ marginLeft: 24, marginRight: 10, width: 300 }}>
                          <Input
                            data-index={index}
                            data-id={data.id}
                            value={this.state.fieldList[index].fieldName}
                            onChange={event => {this.handleChangeFieldName(event, index)}}
                          ></Input>
                        </div>
                        { this.state.fieldList.length > 1 &&
                        <Button type="link" data-index={index} onClick={this.delFieldItem.bind(this, index)}>
                          删除
                        </Button>}
                      </div>
                    )
                  })}
                </div>
              {this.state.fieldList.length < 10 &&
              <div className="apply-info-footer">
                <Button className="add-field-btn" type="primary" onClick={this.addFieldItem.bind(this)}><span style={{ width: 12, height: 12, marginRight: 8 }}><Icon type="plus" /></span>添加</Button>
              </div>}
            </div>
          </Form.Item>
        </div>
      )
    }
  }

  handleChangeDoveFee(e) {
    this.setState({
      isSetDoveFee: e.target.checked ? true : false
    })
    if (e.target.checked) {
      let typeList = this.state.typeList
      typeList.splice(1, typeList.length - 1)
      typeList[0].typeName = '鸽子费deposit'
      this.setState({
        typeList: typeList
      })
    }
  }
  showWithdrawDescription() {
    this.setState({
      showWithdrawDescription: true
    })
  }
  hideWithdrawDescription() {
    this.setState({
      showWithdrawDescription: false
    })
  }
  beforeUpload = (file) => {
    return new Promise((resolve, reject) => {
      let isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/gif';
      let isLt2M = file.size / 1024 / 1024 <= 2;
      if (!isJpgOrPng) {
        message.error('只能上传 JPG/PNG/GIF 格式的文件!');
        return reject(false)
      }
      if (!isLt2M) {
        message.error('图片大小不能超过 2MB!');
        return reject(false);
      }
      return resolve(true);
    });
  }
  validateStartTime(rule, value, callback) {
    let endTime = this.props.form.getFieldValue('activityEndTime')
    if (moment(value).isBefore(moment(moment().format('YYYY-MM-DD HH:mm')))) {
      callback('活动开始时间不能早于当前时间')
    } else {
      if (moment(value).isSameOrAfter(endTime)) {
        callback('活动开始时间不能晚于活动结束时间')
      } else {
        this.props.form.setFieldsValue({activityEndTime: endTime})
        callback()
      }
    }
  }
  validateEndTime(rule, value, callback) {
    let startTime = this.props.form.getFieldValue('activityStartTime')
    if (moment(value).isSameOrBefore(moment(moment().format('YYYY-MM-DD HH:mm')))) {
      callback('活动结束时间不能早于当前时间')
    } else {
      if (moment(value).isSameOrBefore(startTime)) {
        callback('活动结束时间不能早于活动开始时间')
      } else {
        this.props.form.setFieldsValue({activityStartTime: startTime})
        callback()
      }
    }
  }
  handleChangeTypeName = (e, index) => {
    let typeList = this.state.typeList
    typeList[index].typeName = e.target.value
    this.setState({
      typeList: typeList
    })
  }
  handleChangeTypePrice = (value, index) => {
    let typeList = this.state.typeList
    typeList[index].typePrice = value
    this.setState({
      typeList: typeList
    })
  }
  handleChangeTypeInitStock = (value, index) => {
    let typeList = this.state.typeList
    typeList[index].typeInitStock = value
    this.setState({
      typeList: typeList
    })
  }
  handleChangeTypeDeadline = (date, dateString, index) => {
    let typeList = this.state.typeList
    typeList[index].typeDeadline = dateString
    this.setState({
      typeList: typeList
    })
  }
  handleChangeActivityEndTime(date, dateString) {
    let typeList = this.state.typeList
    typeList.forEach((item, index, arr) => {
      if (moment(item.typeDeadline).isSame(moment(this.state.saveActivityEndTime))) {
        arr[index].typeDeadline = dateString
      }
    })
    this.setState({
      typeList: typeList,
      saveActivityEndTime: dateString
    })
  }
  render() {
    if (this.state.isChange) {
      if (this.isLiving) {
        return this._render()
      } else {
        return <div></div>
      }
    } else {
      return this._render()
    }
  }

  _render() {
    const { getFieldDecorator } = this.props.form;
    const { editorState1, editorState2, editorState3, defaultVal, classVal } = this.state
    const uploadButton = (
      <div>
        <Icon type="plus" />
        <div className="ant-upload-text">上传图片</div>
      </div>
    );
    const formItemLayout = {
      labelCol: { span: 7 },
      wrapperCol: { span: 17 },
    };
    const formTailLayout = {
      labelCol: { span: 7 },
      wrapperCol: { span: 8, offset: 16 },
    };
    const formMiddleLayout = {
      labelCol: { span: 7 },
      wrapperCol: { span: 17, offset: 7 },
    };
    const imgLabel = <span className="ant-form-item-required">活动封面</span>
    const skuLabel = <span className="ant-form-item-required">活动规格</span>
    const applyInfoLabel = <span>希望报名者参与问卷填写<Tooltip title="你可以设置表单，对报名者发起问卷调查。注意这也可能影响报名率哦。建议你在免费活动中使用。"><Icon type="question-circle" style={{ color: "#E35F4B", marginLeft: 5 }}/></Tooltip></span>
    const descriptionLabel = <span>活动内容<Tooltip title="请务必在活动内容里注明与退款或者任何可能引起纠纷相关的必要信息。"><Icon type="question-circle" style={{ color: "#E35F4B", marginLeft: 5 }}/></Tooltip></span>
    return (
      <div className="activity-edit">
        <div className="activity-edit-container">
          <Breadcrumb className="activity-edit-breadcrumb">
            <Breadcrumb.Item>
              <Link to={{pathname: '/my/activity/manage'}}>我发起的活动</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              {this.state.isChange && <span style={{ color: "#E35F4B" }}>编辑活动</span>}
              {!this.state.isChange && <span style={{ color: "#E35F4B" }}>新建活动</span>}
            </Breadcrumb.Item>
          </Breadcrumb>
          <div className="activity-edit-card">
            <Form onSubmit={this.handleSubmit} layout="horizontal">
              <Form.Item label="标题" {...formItemLayout}>
                {getFieldDecorator("activityTitle", {
                  initialValue: this.state.activityTitle,
                  rules: [
                    {
                      required: true,
                      message: "标题不能为空"
                    }, {
                      min: 2,
                      message: "最少2个字"
                    }, {
                      max: 20,
                      message: "不超过20个字"
                    }
                  ]
                })(<Input 
                    style={{ width: 300, height: 32, borderRadius: 2 }} />)}
                <div className="tips">标题最少2个字，不超过20个字</div>
              </Form.Item>

              <Form.Item label={imgLabel} {...formItemLayout}>
                <Upload
                  fileList={this.state.fileList}
                  customRequest={this.customRequest}
                  onPreview={this.handlePreview}
                  onChange={this.handleChange}
                  beforeUpload={this.beforeUpload}
                  listType="picture-card"
                  accept="image/*"
                >
                  {this.state.fileList.length >= 1 ? null : uploadButton}
                </Upload>
                <div className="tips" style={{ marginTop: -10 }}>该图片为活动封面图，横版，尺寸同公众号封面图：900*383px，格式jpg、gif、png，大小不超过2M</div>
              </Form.Item>
              <Modal
                visible={this.state.previewVisible}
                footer={null}
                onCancel={this.handleCancel}
              >
                <img
                  alt="example"
                  style={{ width: "100%" }}
                  src={this.state.previewImage}
                />
              </Modal>

              <Form.Item label="活动分类" {...formItemLayout}>
                {getFieldDecorator("activitySort", {
                  initialValue: this.state.activitySort,
                  rules: [
                    {
                      required: true,
                      message: "活动分类不能为空"
                    }
                  ]
                })(
                  <Select style={{ width: 300, borderRadius: 2 }}>
                    {this.state.classList.map(item => (
                      <Option key={item.id} value={item.id}>
                        {item.name}
                      </Option>
                    ))}
                  </Select>
                )}
              </Form.Item>

                <Form.Item label="活动开始时间" {...formItemLayout}>
                  {getFieldDecorator("activityStartTime", {
                    initialValue: moment(
                      this.state.activityStartTime,
                      "YYYY-MM-DD HH:mm"
                    ),
                    //getValueFromEvent:this.timechange,
                    rules: [
                      {
                        required: true,
                        message: "请设置活动开始时间"
                      },
                      { validator: this.validateStartTime.bind(this)}
                    ]
                  })(
                    <DatePicker
                      style={{ width: 300, borderRadius: 2 }}
                      showTime={{ format: "HH:mm" }}
                      format="YYYY-MM-DD HH:mm"
                    ></DatePicker>
                  )}
                </Form.Item>
                <Form.Item label="活动结束时间" {...formItemLayout}>
                  {getFieldDecorator("activityEndTime", {
                    initialValue: moment(
                      this.state.activityEndTime,
                      "YYYY-MM-DD HH:mm"
                    ),
                    // getValueFromEvent:this.timechange,
                    rules: [
                      {
                        required: true,
                        message: "活动结束时间"
                      },
                      { validator: this.validateEndTime.bind(this)}
                    ]
                  })(
                    <DatePicker
                      style={{ width: 300, borderRadius: 2 }}
                      showTime={{ format: "HH:mm" }}
                      format="YYYY-MM-DD HH:mm"
                      onChange={this.handleChangeActivityEndTime.bind(this)}
                    ></DatePicker>
                  )}
                </Form.Item>
              <Form.Item label="地点" {...formItemLayout}>
                {getFieldDecorator("activitySiteSort", {
                  initialValue: this.state.activitySiteSort,
                  rules: [
                    {
                      required: true,
                      message: "地点不能为空"
                    }
                  ]
                })(
                  <RadioGroup onChange={this.changeAddress}>
                    <Radio value={0}>线上</Radio>
                    <Radio value={1}>线下</Radio>
                  </RadioGroup>
                )}
              </Form.Item>
              {this.renderActivityAddress()}
              
              <Form.Item label={skuLabel} {...formItemLayout}>
                {getFieldDecorator("isSetDoveFee", {
                  valuePropName: 'checked',
                  initialValue: this.state.isSetDoveFee === 1 ? true : false
                })(
                  <Checkbox onChange={this.handleChangeDoveFee.bind(this)}>鸽子费<Tooltip title="如果你发起的活动为免费活动，但又希望收取鸽子费来保证出席率，这里的金额即为鸽子费。报名者现场扫码签到后自动退回。"><Icon type="question-circle" style={{ color: "#E35F4B", marginLeft: 5 }}/></Tooltip></Checkbox>
                )}
              </Form.Item >
              <Form.Item {...formMiddleLayout}>
                <div className="sku-list">
                  <div className="sku-title">
                    <div style={{ marginRight: 85, marginLeft: 24 }}>选项<Tooltip title="报名者将会看到的报名类型，例如: 单人报名"><Icon type="question-circle" style={{ color: "#E35F4B", marginLeft: 5 }}/></Tooltip></div>
                    <div style={{ marginRight: 61 }}>费用<Tooltip title="如果活动为免费，请输入“0”；如果收取费用，请输入希望收取的金额；如果点选了“鸽子费”，这里的金额即为鸽子费，报名者现场扫码签到后自动退回。"><Icon type="question-circle" style={{ color: "#E35F4B", marginLeft: 5 }}/></Tooltip></div>
                    <div style={{ marginRight: 78 }}>库存</div>
                    <div>价格截止时间<Tooltip title="如果“选项”中希望设置“早鸟票”，这里方便你设置早鸟票有效时间。默认日期同活动结束时间。"><Icon type="question-circle" style={{ color: "#E35F4B", marginLeft: 5 }}/></Tooltip></div>
                  </div>
                  <div className="divider"></div>
                  <div className="sku-body">
                    {this.state.typeList && 
                      this.state.typeList.map((data, index) => {
                        return (
                          <div id={index} className="sku-item" key={index}>
                            <div>
                              <Input
                                className="sku-item-input"
                                style={{ marginLeft: 24, width: 104 }}
                                data-index={index}
                                data-typeid={data.typeId}
                                value={this.state.typeList[index].typeName}
                                onChange={event => {this.handleChangeTypeName(event, index)}}
                                disabled={this.props.form.getFieldValue('isSetDoveFee')}
                              ></Input>
                            </div>
                            <div>
                              <InputNumber
                                className="sku-item-input"
                                min={0}
                                data-index={index}
                                data-typeid={data.typeId}
                                value={this.state.typeList[index].typePrice}
                                onChange={value => {this.handleChangeTypePrice(value, index)}}
                              ></InputNumber>
                            </div>
                            <div>
                              <InputNumber
                                min={0}
                                className="sku-item-input"
                                data-index={index}
                                data-typeid={data.typeId}
                                value={this.state.typeList[index].typeInitStock}
                                onChange={value => {this.handleChangeTypeInitStock(value, index)}}
                              />
                            </div>
                            <div>
                              <DatePicker
                                style={{ minWidth: 140 }}
                                showTime={{ format: "HH:mm" }}
                                format="YYYY-MM-DD HH:mm"
                                data-index={index}
                                data-typeid={data.typeId}
                                value={moment(this.state.typeList[index].typeDeadline)}
                                onChange={(date, dateString) => { this.handleChangeTypeDeadline(date, dateString, index)}}
                              ></DatePicker>
                            </div>
                            { this.state.typeList.length > 1 &&
                            <div style={{ fontSize: 12, lineHeight: 17 }}>
                              <Button type="link" onClick={this.delItem.bind(this, index)}>删除</Button>
                            </div>}
                          </div>
                        )
                      })}
                  </div>
                  <div className="sku-footer">
                    <div style={{ marginLeft: 264, color: "#E35F4B" }} onMouseOver={this.showWithdrawDescription.bind(this)} onMouseOut={this.hideWithdrawDescription.bind(this)}><Icon type="question-circle" style={{ color: "#E35F4B", marginRight: 5 }}/>提现说明</div>
                    { !this.state.isSetDoveFee &&
                    <Button className="add-sku-btn" type="primary" onClick={this.addItem.bind(this)}><span style={{ width: 12, height: 12, marginRight: 8 }}><Icon type="plus" /></span>添加</Button>}
                  </div>
                  { this.state.showWithdrawDescription &&
                  <div className="withdraw-description" id="withdraw-description">
                    <div className="description-title">提现说明</div>
                    <div className="description-body">
                    <div>1. 提现与打款：活动结束后三个工作日后，您可以登录网页端，在“活动中心”发起提现，每个账号每天可以提现1次，每次提现金额不超过5000元。九五外将在一个工作日内完成审核打款。</div>
                    <div style={{ marginTop: 15 }}>2. 手续费说明：</div>
                    <div className="withdraw-commission">
                      <div className="withdraw-commission-free">
                        <div>免费活动</div>
                        <div style={{ fontSize: 18, marginTop: 8, marginBottom: 8 }}>￥ 0</div>
                        <div>(无手续费)</div>
                      </div>
                      <div className="withdraw-commission-pay">
                        <div>付费活动，收取少量手续费</div>
                  <div style={{ fontSize: 18, marginTop: 8, marginBottom: 8 }}>每笔订单金额 x {localStorage.getItem("commissionRate")}%</div>
                        <div>(手续费已包括微信收取的0.6%支付通道费)</div>
                      </div>
                    </div>
                    <div style={{ marginTop: 15 }}>3. 如有疑问，您可以直接联系九五外客服（九五外小程序>我，联系客服）</div>
                    </div>
                  </div>}
                </div>
              </Form.Item>
              <Form.Item label={applyInfoLabel}  {...formItemLayout}>
                {getFieldDecorator('applyInfo', { valuePropName: 'checked', initialValue: this.state.applyInfo == 1 })(<Switch onChange={this.handleChangeApplyInfo.bind(this)}/>)}
              </Form.Item>
              {this.renderActivityApplyCustomField()}

              <Form.Item label={descriptionLabel} {...formItemLayout}>
                {getFieldDecorator("activityDescription", {
                  initialValue: this.state.activityDescription,
                  getValueFromEvent: this.handleEditorChange,
                  rules: [
                    {
                      required: true,
                      message: "活动内容不能为空"
                    }
                  ]
                })(
                  <BraftEditor
                    media={{ uploadFn: this.customRequest }}
                    style={{
                      border: "1px solid rgb(217,217,217)",
                      borderRadius: "2px",
                      height: "380px",
                      width: "600px"
                    }}
                    placeholder="请直接编辑或者从微信公众号后台编辑器中复制粘贴素材，不要从网页中直接复制。"
                  />
                )}
              </Form.Item>
              {/* <Form.Item label="活动流程">
                {getFieldDecorator("activityFlow", {
                  initialValue: this.state.activityFlow,
                  getValueFromEvent: this.handleEditorChange,
                  rules: [
                    {
                      required: true,
                      message: "活动流程不能为空"
                    }
                  ]
                })(
                  <BraftEditor
                    media={{ uploadFn: this.customRequest }}
                    style={{
                      border: "1px solid rgb(217,217,217)",
                      borderRadius: "4px",
                      height: "380px"
                    }}
                  />
                )}
              </Form.Item>
              <Form.Item label="报名须知">
                {getFieldDecorator("activityApplyNotice", {
                  initialValue: this.state.activityApplyNotice,
                  getValueFromEvent: this.handleEditorChange,
                  rules: [
                    {
                      required: true,
                      message: "报名须知不能为空"
                    }
                  ]
                })(
                  <BraftEditor
                    media={{ uploadFn: this.customRequest }}
                    style={{
                      border: "1px solid rgb(217,217,217)",
                      borderRadius: "4px",
                      height: "380px"
                    }}
                  />
                )}
              </Form.Item> */}
              <Form.Item label="活动状态" {...formItemLayout}>
                {getFieldDecorator("status", {
                  initialValue: this.state.status,
                  rules: [
                    {
                      required: true,
                      message: "活动状态不能为空"
                    }
                  ]
                })(
                  <div>
                    {(this.state.status === 'reviewing' || this.state.status === 'draft') &&
                    <RadioGroup onChange={this.changeStatus.bind(this)} value={this.state.status}>
                      <Radio value={"reviewing"}>上线</Radio>
                      <Radio value={"draft"}>草稿</Radio>
                    </RadioGroup>}
                    {!(this.state.status === 'reviewing' || this.state.status === 'draft') && 
                    <RadioGroup value={this.state.status}>
                      <Radio value={this.state.status}>{activityFilter.status[this.state.status]}</Radio>
                    </RadioGroup>}
                  </div>
                )}
              </Form.Item>
              <Form.Item {...formTailLayout}>
                <Button className="preview-btn" onClick={this.preview} type="primary" ghost>
                  <span>预览 </span> 
                </Button>
                <Button className="save-btn" type="primary" htmlType="submit">
                  {this.state.addTit}
                </Button>
              </Form.Item>
            </Form>
            {this.renderPreview()}
          </div>
        </div>
      </div>
    );
  }
}

const activityEdit = Form.create()(ActivityEdit);
export default activityEdit;
