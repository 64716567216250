import React, { Component } from 'react'
import { Form, Input, Row, Col, Button } from 'antd'
import './index.less'
import { Ajax } from '../../util/ajax'
import FormItem from 'antd/lib/form/FormItem'
import eventBus from '../../eventBus'

class RegisterForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      image: '',
      verKey: '',
      verCode: '',
      loading: false,
      yztime: 59,
      phone: '',
      codeDisabled: true,
      unionid: localStorage.getItem("unionid") ? localStorage.getItem("unionid") : null
    }
  }
  handleSubmit(e) {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        console.log('Received values of form: ', values);
        let url = ''
        if (this.props.type === 'bindPhone') {
          url = '/login/bind/phone'
          values.unionid = this.state.unionid
        } else if (this.props.type === 'forgetPassword') {
          url = '/login/reset/password'
        }
        Ajax.POST(url, values).then(r => {
          if (r.code === 200) {
            localStorage.setItem('phone', r.data.phone)
            localStorage.setItem('nickname', r.data.nickname)
            localStorage.setItem('userId', r.data.userId)
            localStorage.setItem('avatarUrl', r.data.avatarUrl)
            localStorage.setItem('isLogin', true)
            localStorage.setItem('userToken', r.data.token)
            localStorage.setItem('currentCityId', r.data.currentCityId)
            localStorage.setItem('currentCityName', r.data.currentCityName)
            localStorage.setItem('isAllowPublish', r.data.isAllowPublish)
            localStorage.setItem('commissionRate', r.data.commissionRate)
            if (r.data.userType) {
              localStorage.setItem('userType', r.data.userType)
            }
            eventBus.emit('userLogined')
            this.props.history.push({pathname: '/'})
          }
        })
      }
    });
  }

  componentDidMount() {
    this.getCaptcha()
    eventBus.addListener('registerModalClosed', () => {
      this.props.form.resetFields()
    })
  }
  count() {
    let { yztime } = this.state;
    let siv = setInterval(() => {
      this.setState({ yztime: (yztime--) }, () => {
        if (yztime <= -1) {
          clearInterval(siv);　　//倒计时( setInterval() 函数会每秒执行一次函数)，用 clearInterval() 来停止执行:
          this.setState({ loading: false, yztime: 59 })
        }
      });
    }, 1000);
  }
  sendSms() {
    let phone = this.props.form.getFieldValue('phone')
    let verCode = this.props.form.getFieldValue('captcha')

    this.setState({
      loading: true
    })
    if (this.state.yztime !== 0) {
      this.count()
    }
    Ajax.POST(`/login/send/code?phone=${phone}&verKey=${this.state.verKey}&verCode=${verCode}`).then(r => {
      console.log('smsok')
    })
  }
  getCaptcha() {
    Ajax.GET('/login/captcha').then(r => {
      if (r.code === 200) {
        this.setState({
          verKey: r.data.key,
          image: r.data.image
        })
      }
    })
  }
  validatePhone(rule, value, callback) {
    if (!(/^1[0-9]{10}$/.test(value))) {
      this.setState({
        codeDisabled: true
      })
      callback('请输入正确的手机号')
    } else {
      if (this.props.type === 'bindPhone') {
        Ajax.POST(`/user/check/phone?phone=${value}`).then(r => {
          if (r.code === 200) {
            if (r.data === 'exist') {
              this.setState({
                codeDisabled: true
              })
              callback('该手机号已注册')
            } else {
              if (this.props.form.getFieldValue('captcha') !== undefined) {
                this.setState({
                  codeDisabled: false
                })
              }
              callback()
            }
          }
        })
      } else if (this.props.type === 'forgetPassword') {
        Ajax.POST(`/user/check/phone?phone=${value}`).then(r => {
          if (r.code === 200) {
            if (r.data === 'exist') {
              if (this.props.form.getFieldValue('captcha') !== undefined) {
                this.setState({
                  codeDisabled: false
                })
              }
              callback()
            } else {
              this.setState({
                codeDisabled: true
              })
              callback('该手机号未注册')
            }
          }
        })
      }
    }
  }
  validateCaptcha(rule, value, callback) {
    if (value.length < 4) {
      this.setState({
        codeDisabled: true
      })
      callback('图形验证码错误')
    } else {
      Ajax.POST(`/login/check/captcha?verCode=${value}&verKey=${this.state.verKey}`).then(r => {
        if (r.code === 200) {
          if (r.data === 'ok') {
            if (this.props.form.getFieldValue('phone') !== undefined) {
              this.setState({
                codeDisabled: false
              })
            }
            callback()
          } else {
            this.setState({
              codeDisabled: true
            })
            callback('图形验证码错误')
          }
        }
      })
    }
  }
  validateSmsCode(rule, value, callback) {
    let phone = this.props.form.getFieldValue('phone');
    if (value !== undefined) {
      Ajax.POST(`/login/check/smsCode?phone=${phone}&code=${value}`).then(r => {
        if (r.code === 200) {
          if (r.data === 'ok') {
            callback()
          } else {
            callback('短信验证码错误')
          }
        }
      })
    }
  }
  validatePassword(rule, value, callback) {
    let repeatPassword = this.props.form.getFieldValue('repeatPassword')
    if (repeatPassword !== undefined && repeatPassword !== value) {
      callback('两次输入的密码不一致')
    } else {
      callback()
    }
  }
  validateRepeatPassword(rule, value, callback) {
    let password = this.props.form.getFieldValue('password')
    if (password !== undefined && password !== value) {
      callback('两次输入的密码不一致')
    } else {
      callback()
    }
  }
  changeCaptcha() {
    this.getCaptcha()
  }
  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <div className="register-form">
        <Form onSubmit={this.handleSubmit.bind(this)} className="login-form">
          <FormItem>
            {getFieldDecorator('phone', {
              rules: [{ required: true, message: '请输入您的手机号!' },
              { validator: this.validatePhone.bind(this)}],
            })(
              <Input
                placeholder="输入11位手机号"
              />,
            )}
          </FormItem>
          <FormItem>
            <Row gutter={20}>
              <Col span={15}>
              {getFieldDecorator('captcha',{
                rules: [{ required: true, message: '请输入图形验证码!' },
                { validator: this.validateCaptcha.bind(this) }],
              })(
                <Input
                  placeholder="输入图形验证码"
                />
              )}
              </Col>
              <Col span={6}>
                { this.state.image &&
                <Button className="ver-code" type="link" onClick={this.changeCaptcha.bind(this)}><img id="verImg" src={this.state.image} width="100px" height="30px"/></Button>}
              </Col>
            </Row>
          </FormItem>
          <FormItem>
            <Row gutter={20}>
              <Col span={15}>
                {getFieldDecorator('code', {
                  rules: [{ required: true, message: '请输入短信验证码!' },
                  { validator: this.validateSmsCode.bind(this) }],
                  validateTrigger: 'onBlur'
                })(
                  <Input
                    placeholder="输入短信验证码"
                  />
                )}
              </Col>
              <Col span={6}>
                <Button loading={this.state.loading} type="default"disabled={this.state.codeDisabled} onClick={this.sendSms.bind(this)}>{this.state.loading ? this.state.yztime + '秒' : '获取验证码'}</Button>
              </Col>
            </Row>
          </FormItem>
          <FormItem>
            {getFieldDecorator('password', {
              rules: [{ required: true, message: '请输入密码!' },
              {min: 5, message: '密码长度不能低于 5 位'},
              {max: 20, message: '密码长度不能超过 20 位'},
              { validator: this.validatePassword.bind(this)}],
              validateTrigger: 'onBlur'
            })(
              <Input
                placeholder="输入密码"
                type="password"
              />
            )}
          </FormItem>
          <FormItem>
            {getFieldDecorator('repeatPassword', {
              rules: [{ required: true, message: '请再次输入密码!' },
              {min: 6, message: '密码长度不能低于 5 位'},
              {max: 30, message: '密码长度不能超过 20 位'},
              { validator: this.validateRepeatPassword.bind(this)}],
              validateTrigger: 'onBlur'
            })(
              <Input
                placeholder="再次输入密码"
                type="password"
              />
            )}
          </FormItem>
          <FormItem>
            <Button type="primary" htmlType="submit" className="register-form-button" block>
              确定
            </Button>
          </FormItem>
        </Form>
      </div>
    )
  }
}

const registerForm = Form.create()(RegisterForm)

export default registerForm
