import React, { Component } from 'react'
import {Layout, Popover, Button, Icon, Input, Modal, Tabs, message, Row, Col, Divider } from 'antd'
import { Route } from 'react-router-dom'
import{ Link } from 'react-router-dom'
import './index.less'
import { Ajax } from '../../util/ajax';
import logo from '../../asserts/images/logo.png'
import environment from '../../asserts/images/environment@2x.png'
import searchIcon from '../../asserts/images/Group4@2x.png'
import mpQrcodeImg from '../../asserts/images/jiuwuwaimp.png'
import homepage from '../homepage'
import city from '../city'
import activityList from '../activitiyList'
import eventBus from '../../eventBus/index'
import RegisterForm from '../registerForm'
import LoginForm from '../loginForm'
import login from '../login'
import bindPhone from '../bindPhone'
import { removeCookie } from '../../util/util'
import UserInfoForm from '../userInfoForm'
import loginLoading from '../loginLoading'
import testLoginLoading from '../testLoginLoading'
const { Header, Content } = Layout
const { Search } = Input
const { TabPane } = Tabs

export default class HomeIndex extends Component {
  constructor(props) {
    super(props)
    this.state = {
      currentCity: {
        id: 0,
        name: '全国'
      },
      hotCities: [
        {
          id: 0,
          name: '全国'
        }
      ],
      activityTitle: '',
      isAuth: false,
      isLogin: localStorage.isLogin ? true : false,
      wechatState: Math.ceil(Math.random()*1000),
      publishDisable: false,
      visible: false,
      qrcodeImg: null
    }
  }
  componentDidMount() {
    if (localStorage.getItem('userToken')) {
      this.setState({
        publishDisable: (localStorage.getItem('isAllowPublish') && localStorage.getItem('isAllowPublish') == 0 ) ? true : false,
        currentCity: {
          id: localStorage.getItem('currentCityId'),
          name: localStorage.getItem('currentCityName')
        }
      })
    }
    if (this.props.history.location.state && this.props.history.location.state.cityId) {
      console.log('cityName',this.props.history.location.state.cityName )
      this.setState({
        currentCity: {
          id: this.props.history.location.state.cityId,
          name: this.props.history.location.state.cityName
        }
      })
    }
    eventBus.addListener('cityChange',(city)=>{
      this.setState({currentCity:city})
    })
    this.getHotCities()
    eventBus.addListener('userLogined', () => {
      this.setState({
        isLogin: true,
      })
    })
  }
  getQrcodeImg() {
    Ajax.POST(`/activity/getQRCode?page=pages/index/index&scene=''`).then(r => {
      if (r.code === 200) {
        this.setState({
          qrcodeImg: `data:image/png;base64,${r.data}`
        })
      }
    })
  }
  getHotCities() {
    Ajax.POST('/city/hot').then(r => {
      if(r.code === 200) {
        this.setState({
          hotCities: this.state.hotCities.concat(r.data)
        })
      }
    })
  }
  onCityChange(city) {
    this.setState({
      currentCity: city
    })
    if (localStorage.getItem('userToken')) {
      localStorage.setItem("currentCityId", city.id)
      localStorage.setItem("currentCityName", city.name)
      Ajax.POST(`/user/update/current_city?currentCity=${city.id}`).then(r => {
        if (r.code === 200) {
          console.log('当前城市设置成功')
        }
      })
    }
    if (this.props.location.pathname !== '/') {
      this.props.history.push({pathname: '/', state: {cityId: city.id, cityName: city.name}})
    } else {
      eventBus.emit('headerCityChange', city)
    }
  }
  showCities() {
    return (
      <div className="activity-city">
        <Row gutter={10} className="current-city">
          <Col span={7} className="city-title">当前城市: </Col>
          <Col span={17}>{this.state.currentCity.name}</Col>
        </Row>
        <Divider />
        <Row gutter={10} className="header-hot-city">
          <Col span={7} className="city-title">热门城市: </Col>
          <Col span={17}>
            { this.state.hotCities.map((item, index) => {
              return <Button key={index} className="hot-city-btn" type="link" onClick={this.onCityChange.bind(this, item)}>{item.name}</Button>
            }) }
          </Col>
        </Row>
        <Divider />
        <div className="more-city">
          <div></div>
          <div className="more-city-btn">
            <Link to="/citylist"><span>更多城市</span><Icon type="right" /></Link>
          </div>
        </div>
      </div>
    )
  }
  searchActivity(title) {
    if (this.props.location.pathname !== '/activity/list') {
      this.props.history.push({pathname: '/activity/list', state: {cityId: this.state.currentCity.id, cityName: this.state.currentCity.name, title: title}})
    } else {
      eventBus.emit('headerTitleSearch', {cityId: this.state.currentCity.id, cityName: this.state.currentCity.name, title: title})
    }
  }
  handleClickLogin() {
    this.props.history.push({pathname: '/login'})
  }
  handleClickAddActivity() {
    if (localStorage.getItem('userToken')) {
      if (localStorage.getItem('userType') && (localStorage.getItem('userType') === 'INDIVIDUAL' || localStorage.getItem('userType') === 'ORGANIZATION')) {
        this.props.history.push({pathname: '/my/activity/edit'})
      } else {
        this.setState({
          visible: true
        })
      }
    } else {
      message.warning('未登录，请先登录')
      this.props.history.push({pathname: '/login'})
    }
  }
  handleClickPersonCenter() {
    this.props.history.push({pathname: '/my/profile/setting'})
  }
  handleClickActivityCenter() {
    this.props.history.push({pathname: '/my/activity/manage'})
  }
  logout() {
    var result = Ajax.POST('/manager/loginOut').then(r => {
      localStorage.clear()
      this.setState({
        isLogin: false
      })
      this.props.history.push({pathname: '/'})
    })
  }
  hideModal() {
    this.setState({
      visible: false
    })
    eventBus.emit('hideUserProfileModal')
  }
  render() {
    const qrcodeImg = <div style={{ textAlign: "center", width: 160, height: 160 }}>
      <img style={{ width: 160, height: 160 }} src={mpQrcodeImg} alt="" />
    </div>
    const menu = (
      <div style={{ width: 80, textAlign: "center" }}>
        <div><Button type="link" style={{ padding: 0 }} onClick={this.handleClickActivityCenter.bind(this)}>活动中心</Button></div>
        <div><Button type="link" style={{ padding: 0 }} onClick={this.handleClickPersonCenter.bind(this)}>个人中心</Button></div>
        <div><Button type="link" style={{ padding: 0 }} onClick={this.logout.bind(this)}>退出登录</Button></div>
      </div>
    )
    return (
      <Layout>
        <Header style={{ position: 'fixed', zIndex: 100, width: '100%', background: '#fff' }}>
          <div className="index-header">
            <div className="index-header-content">
              <Link to="/">
                <div className="logo">
                  <img src={logo} alt="logo" />
                  <span>九五外 来淘趣</span>
                </div>
              </Link>
              <div className={ localStorage.getItem('userToken') ? "activity-search searchmr" : "activity-search" }>
                <Popover content={this.showCities()} placement="bottomLeft">
                  <Button type="link" style={{ color: '#3C3C3C', padding: 0 }}>
                    <img src={environment} alt="" />
                    {localStorage.getItem("currentCityId") &&
                    <span className="city-name">{localStorage.getItem("currentCityName")}</span>}
                    {!localStorage.getItem("currentCityId") &&
                    <span className="city-name">{this.state.currentCity.name}</span>}
                    <Icon type="caret-down" className="more-city-icon"/>
                  </Button>
                </Popover>
                <div className="activity-search-input">
                  <Search
                    prefix={<span className="search-icon"><img src={searchIcon} alt=""/></span>}
                    enterButton="搜索 "
                    size="default"
                    onSearch={value => this.searchActivity(value)}
                  />
                </div>
              </div>
              <div className="index-header-operation">
                
                {!localStorage.getItem('userToken') &&
                  <span className="operation-login">
                    <Popover content={qrcodeImg} placement="bottom">
                      <Button type="primary" ghost style={{ width: 72, height: 36, marginRight: 15 }}>小程序</Button>
                    </Popover>
                    <Button type="primary" style={{ width: 86, height: 36, fontSize: 14, marginRight: 15 }} onClick={this.handleClickAddActivity.bind(this)}>发起活动</Button>
                    <Button type="primary" ghost style={{ width: 58, height: 36 }} onClick={this.handleClickLogin.bind(this)}>登录 </Button>
                  </span>}
                {localStorage.getItem('userToken') &&
                  <span className="operation-logout">
                    <Popover content={qrcodeImg} placement="bottom">
                      <Button type="primary" ghost style={{ width: 72, height: 36, marginRight: 15 }}>小程序</Button>
                    </Popover>
                    <Button type="primary" style={{ width: 86, height: 36, fontSize: 14, marginRight: 15 }} onClick={this.handleClickAddActivity.bind(this)} disabled={this.state.publishDisable}>发起活动</Button>
                    <Popover content={menu} placement="bottom">
                      <Button type="link" style={{ padding: 0, height: 40 }}><div className="user-avatar"><img src={localStorage.getItem("avatarUrl")} alt=""/></div></Button>
                    </Popover>
                    {/* <Button type="primary" ghost style={{ width: 86, height: 36, marginRight: 15 }} onClick={this.handleClickPersonCenter.bind(this)}>个人中心</Button>
                    <Button type="primary" ghost style={{ width: 58, height: 36 }} onClick={this.logout.bind(this)}>退出 </Button> */}
                  </span>}
              </div>
              <Modal
                title="完善基本信息"
                visible={this.state.visible}
                onCancel={this.hideModal.bind(this)}
                footer={null}
                width={600}>
                <UserInfoForm history={this.props.history}></UserInfoForm>
              </Modal>
            </div>
          </div>
        </Header>
        <Content>
          <Route path="/" exact component={homepage}></Route>
          <Route path="/citylist" exact component={city}></Route>
          <Route path="/activity/list" exact component={activityList}></Route>
          <Route path="/login" exact component={login}></Route>
          <Route path="/login/load" exact component={loginLoading}></Route>
          <Route path="/login/test/load" exact component={testLoginLoading}></Route>
          <Route path="/bind/phone" exact component={bindPhone}></Route>
        </Content>
      </Layout>
    )
  }
}
