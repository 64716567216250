import React, {Component} from 'react'
import './index.less'
import register1 from '../../asserts/images/register1.jpg'
import register2 from '../../asserts/images/register2.jpg'
import kefu from '../../asserts/images/kefu.png'
import manage7 from '../../asserts/images/manage7.jpg'
import mpqrcode from '../../asserts/images/mpqrcode.jpg'
import publish3 from '../../asserts/images/publish3.jpg'
import publish4 from '../../asserts/images/publish4.jpg'
import publish5 from '../../asserts/images/publish5.jpg'
import publish6 from '../../asserts/images/publish6.jpg'
import title01 from '../../asserts/images/title01.jpg'
import withdraw9 from '../../asserts/images/withdraw9.jpg'
import check8 from '../../asserts/images/check8.jpg'
import contact5 from '../../asserts/images/contact5.jpg'
import publishTip from '../../asserts/images/publishtip.jpg'
import manage19 from '../../asserts/images/manage19.png'

export default class PublishGuide extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    let state = this.props.location.state;
    console.log('state.anchorName',state.anchorName)
    this.scrollToAnchor(state.anchorName)
  }

  componentDidUpdate() {
    let state = this.props.location.state;
    console.log('state.anchorNameqqq',state.anchorName)
    this.scrollToAnchor(state.anchorName)
  }

  scrollToAnchor = (anchorName) => {
    console.log(111)
    if (anchorName) {
        let anchorElement = document.getElementById(anchorName);
        if(anchorElement) {
          anchorElement.scrollIntoView({block: 'center'}); 
        }
    }
  }
  render() {
    return (
      <div className="publish-guild">
        <div className="publish-content">
          <div style={{ marginBottom: 10 }}><span className="welcome">欢迎加入「九五外 来淘趣」，成为活动发起人。</span></div>
          <span className="welcome">为了您能够更好地了解如何使用「九五外 来淘趣」服务，请详细阅读以下发起人指南：</span>
          <div className="title-img">
            <img src={title01} alt="" />
          </div>
          <div id="register" style={{ fontSize: 22, fontWeight: 800, marginBottom: 15 }}>注册/登录</div>
          <div style={{ fontSize: 18, fontWeight: 600, marginBottom: 10 }}>手机端注册：</div>
          <div style={{ marginBottom: 8 }}>1. 点击“发起” - “成为活动发起人”</div>
          <div style={{ marginBottom: 8 }}>2. 填写基本信息 - “保存”</div>
          <div style={{ marginBottom: 8 }}>3. “一键复制网址”，在网页端发布活动</div>
          <div className="title-img">
            <img src={register1} alt="" />
          </div>
          <div style={{ fontSize: 18, fontWeight: 600, marginBottom: 10 }}>网页端注册/登录：</div>
          <div style={{ marginBottom: 8 }}>1. 首次登录网页端，点击右上角登录，使用微信扫码登录；</div>
          <div style={{ marginBottom: 8 }}>2. 登录后绑定手机号，填写基本信息</div>
          <div style={{ marginBottom: 8 }}>3. “一键复制网址”，在网页端发布活动</div>
          <div className="title-img">
            <img src={register2} alt="" />
          </div>
          <div id="publish" style={{ fontSize: 22, fontWeight: 800, marginBottom: 15 }}>发布活动</div>
          <div style={{ fontSize: 18, fontWeight: 600, marginBottom: 10 }}>1. 筛选报名者</div>
          <div style={{ marginBottom: 8 }}>您可以设置问卷/信息标题，通过筛选年龄、职位、爱好等，提前了解报名者</div>
          <div className="title-img">
            <img src={publish3} alt="" />
          </div>
          <div style={{ fontSize: 18, fontWeight: 600, marginBottom: 10 }}>2. 编辑活动内容</div>
          <div style={{ marginBottom: 8 }}>你可以直接编辑或从微信公众号后台编辑器中复制粘贴素材，调整排版。从网页中直接复制会使排版错乱；为直观查看排版效果，建议使用谷歌浏览器；</div>
          <div style={{ marginBottom: 8, color: "#e90716" }}>（注：编辑器内可能会出现图片不显示问题，不影响正常发布）</div>
          <div className="title-img">
            <img src={publishTip} alt="" />
          </div>
          <div style={{ marginBottom: 8 }}>插入图片或者视频：在编辑器中点击“媒体图标”，直接点击上传媒体素材或拖动文件到媒体库；若素材为网络资源 -点击媒体库右下角“添加网络”，按素材类别添加媒体来源地址。</div>
          <div className="title-img">
            <img src={publish4} alt="" />
          </div>
          <div style={{ fontSize: 18, fontWeight: 600, marginBottom: 10 }}>3. 设置票种/报名选项</div>
          <div style={{ marginBottom: 8 }}>你可以设定活动收费或者免费，免费活动的费用设置为“0”；你可以设置不同的报名选项，以及该票种的截止时间。例如选项中设置：单人报名早鸟票，那么它的价格截止时间可以设置为早于其他报名选项时间，通常费用也会更优惠；对于收费的活动，建议活动内容中备注“报名取消”规则。</div>
          <div className="title-img">
            <img src={publish5} alt="" />
          </div>
          <div style={{ marginBottom: 8 }}>你也可以在“活动中心”—“活动管理”中，选择已经发布的类似活动直接“复制”一个新的活动，然后基于已有的活动信息进行修改。</div>
          <div className="title-img">
            <img src={publish6} alt="" />
          </div>
          <div id="manage" style={{ fontSize: 22, fontWeight: 800, marginBottom: 15 }}>管理活动</div>
          <div style={{ fontSize: 18, fontWeight: 600, marginBottom: 10 }}>1. 活动中心</div>
          <div style={{ marginBottom: 8 }}>下载活动报名码：在“活动中心”-“活动管理”中可下载活动报名码，图片为透明png文件。修改库存/下线活动：在已有用户报名的情况下，无法下线活动，如有特殊原因需临时取消活动/终止报名，可修改库存为“0”，或与报名人沟通取消报名后，下线活动。</div>
          <div className="title-img">
            <img src={manage19} alt="" />
          </div>
          <div style={{ fontSize: 18, fontWeight: 600, marginBottom: 10 }}>2. 报名管理</div>
          <div style={{ marginBottom: 8 }}>下在活动中心，你可以查看报名人员详情、取消报名信息并进行退款审核，数据信息实时展示，一目了然。</div>
          <div className="title-img">
            <img src={manage7} alt="" />
          </div>
          <div style={{ fontSize: 18, fontWeight: 600, marginBottom: 10 }}>3. 验票入场</div>
          <div style={{ marginBottom: 8 }}>支持线下活动现场扫码验票；报名者凭“签到码”即可入场；发起方/人通过微信小程序进入“我发布的活动”中点击“扫码验票”，随时随地，通过扫码进行签到/验票。用户报名成功后，可在“我报名的活动”中查看签到码。</div>
          <div className="title-img">
            <img src={check8} alt="" />
          </div>

          <div id="withdraw" style={{ fontSize: 22, fontWeight: 800, marginBottom: 15 }}>提现/打款</div>
          <div style={{ marginBottom: 8 }}>活动结束三个工作日后，可通过网页端在“账户中心”发起提现；每个账号每天可提现一次，每次提现金额不超过5000元，九五外将在一个工作日内完成提现审核，审核通过后将打款至注册账户的微信钱包（注：请确保该账户已完成实名认证）。</div>
          <div className="title-img">
            <img src={withdraw9} alt="" />
          </div>
          <div className="title-img" id="contact">
            <img src={contact5} alt="" />
          </div>
          <div style={{ marginBottom: 8 }}>如果您的活动需要申请［九五外］支持，请联系客服小九；如果你也想成为发起方/发起人，登录网址：https://www.95wai.com/ ；关注公众号：九五外（jiuwuwai）了解更多。</div>
          <div className="contact-img-group">
            <div className="contact-img"><img src={kefu} alt="" />客服小九微信</div>
            <div className="contact-img"><img src={mpqrcode} alt="" />扫码进入程序码</div>
          </div>
        </div>
      </div>
    )
  }
}
