import React, {Component} from 'react'
import { Tabs, Table, message, Breadcrumb } from 'antd'
import { Ajax } from '../../util/ajax'
import { Link } from 'react-router-dom'
import './index.less'
const { TabPane } = Tabs

export default class ActivityCheckInList extends Component {
  constructor(props) {
    super(props)
    let state = this.props.location.state;
    this.state = {
      activityId: state.activityId,
      checkInList: [],
      pageNum: 1,
      pageSize: 10,
      condition: '',
      count: 0,
      tableLoading: false,
      checkInColumns: [
        {
          title: '头像',
          dataIndex: 'avatar_url',
          width: 150,
          render: (record) => {
            return <img src={record} alt="" style={{ width: '40px', height: '40px' }} />
          }
        }, {
          title: '昵称',
          dataIndex: 'nickname',
        }, {
          title: '签到时间',
          dataIndex: 'check_in_time'
        }
      ]
    }
  }
  componentDidMount() {
    this.getCheckInList(this.state.pageSize, this.state.pageNum)
  }
  getCheckInList(pageSize, pageNum) {
    this.setState({
      tableLoading: true
    })
    let url = `/apply/getCheckInPeopleList?activityId=${this.state.activityId}&pageSize=${pageSize}&pageNum=${pageNum}`
    if (this.state.condition) {
      url += '&condition=' + this.state.condition
    }
    Ajax.POST(url).then( r =>{
      if(r.code === 200){
        let myData = r.data.list
        let count = r.data.total
        myData.forEach((item, index, arr) => {
            arr[index].key = index
        });
        this.setState({
          checkInList: myData,
          count: count,
          tableLoading: false
        })
      }else{
        message.error(r.message)
      }
    })
  }
  handleTabChange(value) {
    if (value === '1') {
      this.props.history.push({pathname: '/my/activity/apply/manage', state: {activityId: this.state.activityId}})
    } else if (value === '3') {
      this.props.history.push({pathname: '/my/activity/quit/manage', state: {activityId: this.state.activityId}})
    }
  }
  render() {
    var self = this
    return (
      <div className="my-activity-apply-manage">
        <div className="apply-manage-content">
        <Breadcrumb className="apply-manage-breadcrumb">
          <Breadcrumb.Item>
            <Link to={{pathname: '/my/activity/manage'}}>我发起的活动</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item><span style={{ color: "#E35F4B" }}>报名管理</span></Breadcrumb.Item>
        </Breadcrumb>
        <div className="apply-manage-card">
        <Tabs onChange={this.handleTabChange.bind(this)} activeKey="2">
          <TabPane tab="报名人员" key="1">
          </TabPane>
          <TabPane tab="签到人员" key="2">
          </TabPane>
          <TabPane tab="取消报名" key="3">
          </TabPane>
        </Tabs>
        <div className="check-in-list-table">
          <Table
            dataSource={this.state.checkInList}
            columns={this.state.checkInColumns}
            loading={this.state.tableLoading}
            pagination={{  //分页
              total: this.state.count, //数据总数量
              pageSize: this.state.pageSize,  //显示几条一页
              defaultPageSize: this.state.pageSize, //默认显示几条一页
              showSizeChanger: false,  //是否显示可以设置几条一页的选项

              onChange(current) {  //点击改变页数的选项时调用函数，current:将要跳转的页数
                console.log(current)
                // this.gotoThispage(current, this.state.queryInfo.pageSize);
                self.state.pageNum = current;
                self.getCheckInList(self.state.pageSize, self.state.pageNum)
              },
              showTotal() {  //设置显示一共几条数据
                return '共 ' + this.total + ' 条数据';
              }
            }}>
          </Table>
        </div>
        </div>
        </div>
      </div>
    )
  }
}
