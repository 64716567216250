export default {
  refundType: {
    doveFee: '退还鸽子费',
    quitApply: '取消活动报名'
  },
  refundStatus: {
    0: '未退款',
    1: '退款成功',
    2: '退款异常',
    3: '退款关闭',
    4: '退款处理中'
  }
}
