import React, { Component } from 'react'
import { Form, Input, Button, Icon, message } from 'antd'
import './index.less'
import { Ajax } from '../../util/ajax'

class PasswordForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      user: {},
      newPassword: '',
      repeatPassword: ''
    }
  }

  componentDidMount() {
    this.getUserProfile()
  }
  getUserProfile() {
    Ajax.POST(`/user/current`).then(r => {
      if (r.code === 200) {
        this.setState({
          user: r.data,
        })
      }
    })
  }
  validateOldPassword(rule, value, callback) {
    Ajax.POST(`/user/check/password?password=${value}`).then(r => {
      console.log('rdata',r.data)
      if (r.data === 'success') {
        callback()
      } else {
        callback("密码错误，请重新输入")
      }
    })
  }
  validateRepeatPassword(rule, value, callback) {
    let password = this.props.form.getFieldValue('password')
    if (password !== undefined && password !== value) {
      callback('两次输入的密码不一致')
    } else {
      callback()
    }
  }
  handleSubmit(e) {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        Ajax.POST(`/user/update/password?password=${values.password}`).then(r => {
          if (r.code === 200) {
            message.success('新密码设置成功, 请重新登录')
            this.loginAgain()
          }
        })
      }
    })
  }
  loginAgain() {
    Ajax.POST('/manager/loginOut').then(r => {
      if (r.code === 200) {
        localStorage.clear()
        this.props.history.push({pathname: '/login'})
      }
    })
  }
  render() {
    const { getFieldDecorator } = this.props.form
    return (
      <div className="password-setting">
      <div className="password-form-container">
        <div className="card-title">密码设置</div>
        <Form 
        className="password-form"
        layout="horizontal"
        labelCol={{ span: 7 }} wrapperCol={{ span: 17 }}
        onSubmit={this.handleSubmit.bind(this)}>
          <Form.Item label="旧密码">
            {getFieldDecorator('oldPassword', {
              rules: [{ required: true, message: '请输入旧密码!' },
              {validator: this.validateOldPassword.bind(this)}],
              validateTrigger: 'onBlur'
            })(
              <Input.Password className="info-input"/>
            )}
          </Form.Item>
          <Form.Item label="新密码">
            {getFieldDecorator('password', {
              rules: [{ required: true, message: '请输入新密码!' },
              {min: 5, message: '密码长度不能低于 5 位'},
              {max: 20, message: '密码长度不能超过 20 位'}],
              validateTrigger: 'onBlur'
            })(
              <Input.Password className="info-input"/>
            )}
          </Form.Item>
          <Form.Item label="重复新密码">
            {getFieldDecorator('repeatPassword', {
              rules: [{ required: true, message: '请再次输入新密码!' },
              {min: 5, message: '密码长度不能低于 5 位'},
              {max: 20, message: '密码长度不能超过 20 位'},
              { validator: this.validateRepeatPassword.bind(this)}],
              validateTrigger: 'onBlur'
            })(
              <Input.Password className="info-input"/>
            )}
          </Form.Item>
          <Form.Item wrapperCol={{ span: 17, offset: 7 }}>
            <div className="save-btn-container">
            <Button type="primary" htmlType="submit" className="save-btn">
              <span>保存 </span>
            </Button>
            </div>
          </Form.Item>
        </Form>
      </div>
      </div>
    )
  }
}

const passwordForm = Form.create()(PasswordForm)

export default passwordForm
