import React, { Component } from 'react'
import {Row, Col, Divider, Button} from 'antd'
import './index.less'
import { Ajax } from '../../util/ajax'
import eventBus from '../../eventBus/index'

export default class Homepage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      cities: [],
      hotCities: [
        {
          id: 0,
          name: '全国'
        }
      ],
    }
  }

  componentDidMount() {
    this.getHotCities()
    this.getCities()
  }
  getHotCities() {
    Ajax.POST('/city/hot').then(r => {
      if(r.code === 200) {
        this.setState({
          hotCities: this.state.hotCities.concat(r.data)
        })
      }
    })
  }
  getCities() {
    Ajax.POST('/city/list').then(r => {
      console.log(Object.keys(r.data))
      if (r.code === 200) {
        this.setState({
          cities: r.data
        })
      }
    })
  }
  onCityChange(city) {
    if (localStorage.getItem('userToken')) {
      localStorage.setItem("currentCityId", city.id)
      localStorage.setItem("currentCityName", city.name)
      Ajax.POST(`/user/update/current_city?currentCity=${city.id}`).then(r => {
        if (r.code === 200) {
          console.log('当前城市设置成功')
        }
      })
    }
    eventBus.emit('cityChange',city)
    this.props.history.push({pathname: '/', state: {cityId: city.id, cityName: city.name}})
  }
  render() {
    return (
      <div className="city-container">
        <div className="activity-city-module">
          <div className="hot-city">
            <span className="city-label">热门城市:</span>
            <div className="hot-city-list">
              {this.state.hotCities.map((item, index) => {
                return <div className="city-item" key={index}>
                  <Button className="city-btn" type="link" onClick={this.onCityChange.bind(this, item)}>{item.name}</Button>
                </div>
              })}
            </div>
          </div>
          <div className="city-list"></div>
          <Divider />
          <div className="all-city">
            <span className="city-label">按拼音首字母选择:</span>
            {Object.keys(this.state.cities).map((item, index) => {
              return <div className="city-group" key={index}>
                <Row type="flex" justify="center" align="top">
                  <Col span={1}><span className="city-label">{item}</span></Col>
                  <Col span={23}>
                    <span className="city-list">{this.state.cities[item].map((cityItem, cityIndex) => {
                      return <span className="city-item" key={cityIndex}>
                        <Button className="city-btn" type="link" onClick={this.onCityChange.bind(this, cityItem)}>{cityItem.name}</Button>
                      </span>
                    })}</span>
                  </Col>
                </Row>
              </div>
            })}
          </div>
        </div>
      </div>
    )
  }
}