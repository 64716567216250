import React, { Component } from 'react'
import logo from '../../asserts/images/logo.png'
import { Layout, Button } from 'antd';
import "./index.less";
// import { Route } from 'react-router-dom';
//import { Route, Link, Redirect } from 'react-router-dom';
//import { Ajax } from '../../util/ajax'
import { removeCookie } from '../../util/util'
//import { getCookie, removeCookie } from '../../util/util'
import { connect } from "react-redux";
import { login, logout } from "../../redux/login";
//import { IconFont } from '../../components/IconFont'
//import { Lifecycle } from 'react-router'
const { Header, Content } = Layout;
//const SubMenu = Menu.SubMenu;

class Index extends Component {
  rootSubmenuKeys = ['sub1', 'sub2', 'sub4'];
  
  state = {
    openKeys: ['sub1'],
  };

  async  logout() {

    // var result = await Ajax.POST("/manager/loginOut")
    
    removeCookie('islogin');
    removeCookie('username');
    this.props.logout()
  }
  onOpenChange = (openKeys) => {
    const latestOpenKey = openKeys.find(key => this.state.openKeys.indexOf(key) === -1);
    if (this.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      this.setState({ openKeys });
    } else {
      this.setState({
        openKeys: latestOpenKey ? [latestOpenKey] : [],
      });
    }
  }
  render() {
    // const userName = getCookie('username');
    const app = (
      <Layout>
        <Header style={{ position: 'fixed', zIndex: 1, width: '100%', background: '#fff' }}>
          <div className="index-header">
            <div className="logo">
              <img src={logo} alt="logo" />
              <span>九五外</span>
            </div>
            <div className="index-header-operation">
              <Button type="link">注册</Button>
              <Button type="link">登录</Button>
            </div>
          </div>
        </Header>
        <Content>
        </Content>
      </Layout>
    );
    //const login = <Redirect to='/login'></Redirect>;
    //console.log(this.props)
    //return this.props.auth.isAuth ? app : login;
    return app;
  }
}

const mapStateToProps = state => {
    return {
        auth: state.auth
    }
}

const authCreators = { login, logout }

export default connect(mapStateToProps, authCreators)(Index)