import React, { Component } from 'react'
import { Form, Input, Button, Icon, message, Tooltip, InputNumber, DatePicker } from 'antd'
import './index.less'
import moment from 'moment'
import { Ajax } from '../../util/ajax'
import eventBus from '../../eventBus/index'

class ActivitySkuForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      activityId: props.activityId,
      typeList: [],
      activity: null
    }
  }

  componentDidMount() {
    this.getActivity()
  }
  getActivity() {
    Ajax.POST('/activity/get?activityId=' + this.state.activityId).then(r =>{
      if(r.code === 200){
        this.setState({
          activity: r.data,
          typeList: r.data.typeList || [],
        })
      }
    })
  }
  isEmpty(value) {
    if (value === undefined || value === null || value === '') {
      return true
    }

    return false
  }
  handleSubmit(e) {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        let flag = 1
        for (let i = 0; i < this.state.typeList.length; i ++) {
          if (this.isEmpty(this.state.typeList[i].typeInitStock)) {
            message.error('规格库存不能为空')
            flag = 0
            break
          }
        }
        if (flag === 0) {
          return
        }
        Ajax.POST(`/activity/update/types`, {typeParamsList: this.state.typeList}).then(r => {
          if (r.code === 200) {
            message.success('库存修改成功')
            eventBus.emit('modifySkuSuccess')
          }
        })
      }
    })
  }
  handleChangeTypeInitStock = (value, index) => {
    let typeList = this.state.typeList
    typeList[index].typeInitStock = value
    this.setState({
      typeList: typeList
    })
  }
  render() {
    const { getFieldDecorator } = this.props.form
    return (
        <Form 
        className="sku-form"
        layout="horizontal"
        onSubmit={this.handleSubmit.bind(this)}>
          <Form.Item>
            <div className="sku-list">
            <div className="sku-title">
              <div style={{ marginRight: 63, marginLeft: 24 }}>选项<Tooltip title="报名者将会看到的报名类型，例如: 单人报名"><Icon type="question-circle" style={{ color: "#E35F4B", marginLeft: 5 }}/></Tooltip></div>
              <div style={{ marginRight: 63 }}>费用<Tooltip title="如果活动为免费，请输入“0”；如果收取费用，请输入希望收取的金额；如果点选了“鸽子费”，这里的金额即为鸽子费，报名者现场扫码签到后自动退回。"><Icon type="question-circle" style={{ color: "#E35F4B", marginLeft: 5 }}/></Tooltip></div>
              <div style={{ marginRight: 82 }}>库存</div>
              <div>价格截止时间<Tooltip title="如果“选项”中希望设置“早鸟票”，这里方便你设置早鸟票有效时间。默认日期同活动结束时间。"><Icon type="question-circle" style={{ color: "#E35F4B", marginLeft: 5 }}/></Tooltip></div>
            </div>
            <div className="divider"></div>
            <div className="sku-body">
              {this.state.typeList && 
                this.state.typeList.map((data, index) => {
                return (
                  <div id={index} className="sku-item" key={index}>
                    <div>
                      <Input
                        disabled={true}
                        className="sku-item-input"
                        style={{ marginLeft: 24 }}
                        data-index={index}
                        data-typeid={data.typeId}
                        value={this.state.typeList[index].typeName}
                      ></Input>
                    </div>
                    <div>
                      <InputNumber
                        disabled={true}
                        className="sku-item-input"
                        min={0}
                        data-index={index}
                        data-typeid={data.typeId}
                        value={this.state.typeList[index].typePrice}
                      ></InputNumber>
                    </div>
                    <div>
                      <InputNumber
                        min={0}
                        className="sku-item-input"
                        data-index={index}
                        data-typeid={data.typeId}
                        value={this.state.typeList[index].typeInitStock}
                        onChange={value => {this.handleChangeTypeInitStock(value, index)}}
                      />
                    </div>
                    <div>
                      <DatePicker
                        disabled
                        style={{ minWidth: 140 }}
                        showTime={{ format: "HH:mm" }}
                        format="YYYY-MM-DD HH:mm"
                        data-index={index}
                        data-typeid={data.typeId}
                        value={moment(this.state.typeList[index].typeDeadline)}
                      ></DatePicker>
                    </div>
                  </div>
                )
              })}
            </div>
            </div>
          </Form.Item>
          <Form.Item>
            <div className="save-btn-container">
            <Button type="primary" htmlType="submit" className="save-btn">
              <span>保存 </span>
            </Button>
            </div>
          </Form.Item>
        </Form>
    )
  }
}

const activitySkuForm = Form.create()(ActivitySkuForm)

export default activitySkuForm
