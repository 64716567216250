import { message } from 'antd'
const axios = require('axios');

console.log(process.env.NODE_ENV)
axios.defaults.baseURL = '/tcback';
axios.defaults.withCredentials = true;
document.cookie = 'JSESSIONID='+(localStorage.getItem('userToken') ? localStorage.getItem('userToken') : '')
console.log(localStorage.getItem('Token'))
// Add a request interceptor
axios.interceptors.request.use(function (config) {
  // Do something before request is sent
  if (["post", "put", "delete"].includes(config.method)) {
    config.headers = config.headers || {}
    config.headers['token'] = localStorage.getItem('userToken') ? localStorage.getItem('userToken') : ''
  }
  return config;
}, function (error) {
  // Do something with request error
  message.error(error.message, 3)
  return Promise.reject(error);
});

axios.interceptors.response.use(function (response) {


  if (response.data === undefined) {
    message.error("缺少数据字段data")
    return response.data
  }
  
  if (response.data.code === undefined) {
    message.error("缺少状态码")
    return response.data
  }

  if (response.data.code === 3000) {
    console.log('res', response)
    localStorage.removeItem("username")
    localStorage.removeItem("islogin")
    localStorage.removeItem('userToken')
    localStorage.removeItem('isLogin')
    localStorage.removeItem('phone')
    localStorage.clear()
    window.location.replace("/login")
    return response.data
  }


  if ( response.data.code !== 200) {
    message.error(response.data.msg || "服务器出错啦")

    return response.data
  }


 

  if (response.data && response.data.code !== 200) {
    message.error(response.data.msg || "服务器出错啦")

    return response.data
  }

  return response

}, function (error) {
  message.error("服务器出错啦:" + error.message, 3)
  return Promise.reject(error);
});

export const Ajax = {
  /**
   * 封装fetch的get请求方法
   */
  GET: (url) => axios.get(url).then(res => (res.data || {})),
  DELETE: (url) => axios.delete(url).then(res => (res.data || {})),
  /**
   * 封装fetch的post请求方法
   */
  POST: (url, data) => axios.post(url, data).then(res => (res.data || {})),
  /**
   * 封装fetch的put请求方法
   */
  PUT: (url, data) => axios.put(url, data).then(res => (res.data || {}))
}

