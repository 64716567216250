import React, {Component} from 'react'
import { Button, Table, Radio, Modal, message, Tooltip, Icon, Breadcrumb } from 'antd'
import WithdrawForm from '../withdrawForm'
import { Ajax } from '../../util/ajax'
import { Link } from 'react-router-dom'
import './index.less'
import eventBus from '../../eventBus/index'
import { parseAmount } from '../../util/util'

export default class ActivityManage extends Component {
  constructor(props) {
    super(props)
    let state = this.props.location.state
    this.state = {
      activityId: state.activityId || 0,
      incomeList: [],
      pageSize: 10,
      pageNum: 1,
      count: 0,
      tableLoading: false,
      columns: [
        {
          title: '付款时间',
          dataIndex: 'applyTime',
          width: 200
        }, {
          title: '支付方式&支付单号',
          render: (text, record) => {
            return <span>微信支付<br/>
                    sn:<span style={{ fontSize: 12 }}>{record.transactionId}</span></span>
          }
        }, {
          title: '选项',
          dataIndex: 'typeName',
        }, {
          title: '费用',
          dataIndex: 'typePrice',
          render: (text, record) => {
            return <span>￥{parseAmount(record.typePrice)}</span>
          }
        }, {
          title: '数量',
          dataIndex: 'applyPeopleNumber',
          width: 70
        }, {
          title: '订单金额(元)',
          dataIndex: 'applyPrice',
          render: (text, record) => {
            return <span>￥{parseAmount(record.applyPrice)}</span>
          }
        }, {
          title: '手续费率',
          dataIndex: 'commissionRate',
          render: (text, record) => {
            return <span>{record.commissionRate}%</span>
          }
        }, {
          title: '实际收入',
          dataIndex: 'income',
          render: (text, record) => {
            return <span>￥{parseAmount(record.income)}</span>
          }
        }
      ]
    }
  }

  componentDidMount() {
    this.getData(this.state.activityId, this.state.pageSize, this.state.pageNum)
  }
  getData(activityId, pageSize, pageNum) {
    this.setState({
      tableLoading: true
    })
    Ajax.POST(`/apply/income/detail?activityId=${activityId}&pageSize=${pageSize}&pageNum=${pageNum}`).then(r => {
      if (r.code === 200) {
        let myData = r.data.list
        let count = r.data.total
        myData.forEach((item, index, arr) => {
          arr[index].key = index
        });
        this.setState({
          incomeList: myData,
          count: count,
          tableLoading: false
        })
      }
    })
  }
  render() {
    let self = this
    return (
      <div className="activity-income-detail">
        <div className="activity-income-detail-content">
          <Breadcrumb className="activity-income-detail-breadcrumb">
            <Breadcrumb.Item>
              <Link to={{pathname: '/my/finance/manage'}}>收入记录</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item><span style={{ color: "#E35F4B" }}>订单详情</span></Breadcrumb.Item>
          </Breadcrumb>
          <div className="activity-income-detail-card">
            <div className="activity-income-detail-table">
              <Table
                dataSource={this.state.incomeList}
                columns={this.state.columns}
                loading={this.state.tableLoading}
                pagination={{  //分页
                  total: this.state.count, //数据总数量
                  pageSize: this.state.pageSize,  //显示几条一页
                  defaultPageSize: this.state.pageSize, //默认显示几条一页
                  showSizeChanger: false,  //是否显示可以设置几条一页的选项

                  onChange(current) {  //点击改变页数的选项时调用函数，current:将要跳转的页数
                      console.log(current)
                      // this.gotoThispage(current, this.state.queryInfo.pageSize);
                      self.state.pageNum = current;
                      self.getUserActivities(self.state.pageSize, self.state.pageNum)
                  },
                  showTotal() {  //设置显示一共几条数据
                      return '共 ' + this.total + ' 条数据';
                  }
                }}>
              </Table>
            </div>
          </div>
        </div>
      </div>
    )
  }
}