import axios from "axios"
export default function uploadImg(detail) {
    let file = detail.file
    axios.get('/oss/sign')
        .then(res => {
            var resData = res.data.data

            const serverURL = resData.host
            const xhr = new XMLHttpRequest()
            const fd = new FormData()
            const key = `${new Date().getTime()}_${file.name}`;
            fd.append('OSSAccessKeyId', resData.accessid);
            fd.append('policy', resData.policy);
            fd.append('Signature', resData.signature);
            //   fd.append('key', '/tongcheng/' + key);
            fd.append('key', 'tongcheng/' + key);
            fd.append('success_action_status', '200');
            const successFn = (response) => {

                if (detail.onSuccess) {
                    detail.onSuccess(resData.host + '/tongcheng/' + key)
                }

                //editor
                if (detail.success) {
                    detail.success({ url: resData.host + '/tongcheng/' + key })
                }

            }
            const progressFn = (event) => {
                // 上传进度发生变化时调用param.progress
                let percent = event.loaded / event.total * 100

                if (detail.progress) {
                    detail.progress({
                        percent
                    })
                }
                if (detail.onProgress) {
                    detail.onProgress({
                        percent
                    })
                }

            }
            const errorFn = (response) => {
                // 上传发生错误时调用param.error
                if (detail.onError) {
                    detail.onError()
                }


            }
            xhr.upload.addEventListener("progress", progressFn, false)
            xhr.addEventListener("load", successFn, false)
            xhr.addEventListener("error", errorFn, false)
            xhr.addEventListener("abort", errorFn, false)
            fd.append('file', file)
            xhr.open('POST', serverURL, true)
            xhr.send(fd)
        })
    return false
}
