import React, {Component} from 'react'
import { Button, Form, Input, Alert, message} from 'antd'
import { Ajax } from '../../util/ajax'
import './index.less'
import eventBus from '../../eventBus/index'

class WithdrawFrom extends Component {
  constructor(props) {
    super(props)
    this.state = {
    }
  }

  componentDidMount() {
    eventBus.addListener('hideWithdrawModal', () => {
      this.props.form.resetFields()
    })
  }

  handleSubmit(e) {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        console.log('Received values of form: ', values);
        Ajax.POST('/withdraw/add', {
          userId: this.props.userId,
          amount: values.amount
        }).then(r => {
          if (r.code === 200) {
            message.success('申请提现成功')
            eventBus.emit('withdrawSuccess')
          }
        })
      }
    })
  }

  validateAmount(rule, value, callback) {
    if (value === undefined || value.trim() === '' || value === null) {
      callback('提现金额不能为空') 
    } else if (!(/^(\d+)(.\d{0,2})?$/.test(value))) {
      callback('请输入正确的金额，最多两位小数');
    } else if (value < 10) {
      callback('提现金额不能低于10')
    } else if (value > 5000) {
      callback('提现金额不能高于5000')
    } else if (value > this.props.withdrawableBalance) {
      callback('提现金额不能高于可提现金额')
    } else {
      Ajax.POST(`/withdraw/check/amount`, {
        userId: this.props.userId,
        amount: value
      }).then(r => {
        if (r.code == 200) {
          if (r.data === 'amount error') {
            callback('单日提现总额不能高于5000')
          } else if (r.data === 'times error') {
            callback('单日提现次数不能超过1次')
          } else {
            callback()
          }
        }
      })
    }
  }

  render() {
    const { getFieldDecorator } = this.props.form
    const buttonItemLayout = {
      wrapperCol: { span: 14, offset: 5 },
    }
    return (
      <Form layout="horizontal" labelCol={{ span: 4 }} wrapperCol={{ span: 19, offset: 1 }} onSubmit={this.handleSubmit.bind(this)}>
        <Form.Item label="可提现余额(元)">
          <div>
            <div style={{ fontSize: 16 }}>{this.props.withdrawableBalance}</div>
            <div className="withdraw-tips">
              <span className="tips-title">注意：</span><br/>
              <span>1、每日可提现一次，最低提现金额10元，单日最高提现金额5000元；</span><br/>
              <span>2、九五外将在一个工作日内完成提现审核，审核通过后将打款至注册账户的微信钱包（注：请确保该账号已完成实名认证）</span><br/>
            </div>
          </div>
        </Form.Item>
        <Form.Item label="提现金额" className="withdraw-input">
          {getFieldDecorator("amount", {
            rules: [{ validator: this.validateAmount.bind(this) }]
          })(<Input />)}
        </Form.Item>
        <Form.Item label="收款微信">
          <div><span style={{ marginRight: 30 }}>{this.props.nickname}</span><img src={this.props.avatar} alt="" style={{ width: 47, height: 47 }}/></div>
        </Form.Item>
        <Form.Item {...buttonItemLayout}>
        <Button type="primary" htmlType="submit">
          确认提现
        </Button>
        </Form.Item>
      </Form>
    )
  }
}

const withdrawFrom = Form.create()(WithdrawFrom);
export default withdrawFrom;