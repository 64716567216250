import React, { Component } from 'react'
import { Row, Col, Icon, Pagination, Button, Spin } from 'antd'
//import{ Link} from 'react-router-dom'
import './index.less'
import { Ajax } from '../../util/ajax'
import eventBus from '../../eventBus/index'
import activityIcon from '../../asserts/images/Rectangle@2x.png'
import qrcodeIcon from '../../asserts/images/qrcode@2x.png'
import offlineActivtyIcon from '../../asserts/images/offlineActivity@2x.png'
import onlineActivityIcon from '../../asserts/images/onlineActivity@2x.png'

export default class ActivityList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      activities: [],
      activityTitle: '',
      cityId: 0,
      pageNum: 1,
      pageSize: 8,
      activitySortId: 0,
      total: 0,
      acticityQRCodeImg: '',
      showQRCodeActivityId: 0,
      isShowActivityQRCode: false
    }
  }

  componentDidMount() {
    let title = this.state.activityTitle
    let cityId = this.state.cityId
    if (this.props.history.location.state && this.props.history.location.state.cityId) {
      cityId = this.props.history.location.state.cityId
    }
    if (this.props.history.location.state && this.props.history.location.state.title) {
      title = this.props.history.location.state.title
    }
    this.getActivityList(cityId, this.state.activitySortId, this.state.pageSize, this.state.pageNum, title)
    this.setState({
      cityId: cityId,
      activityTitle: title
    })
    eventBus.addListener('headerTitleSearch', (params) => {
      console.log('params', params)
      this.searchActivity(params.title, params.cityId)
    })
  }
  showActivityQRCode(activityId) {
    this.setState({
      qrcodeLoading: true,
      isShowActivityQRCode: true,
      showQRCodeActivityId: activityId
    })
    console.log('qrcodeLoading',this.state.qrcodeLoading)
    Ajax.POST(`/activity/getQRCode?page=pages/activity/detail/index&scene=${activityId}`).then(r => {
      if (r.code === 200) {
        this.setState({
          activityQRCodeImg: `data:image/png;base64,${r.data}`,
          qrcodeLoading: false
        })
      }
      console.log('this.state.activityQRCodeImg',this.state.activityQRCodeImg)
    })
  }
  hideActivityQRCode() {
    this.setState({
      isShowActivityQRCode: false,
      acticityQRCodeImg: '',
      showQRCodeActivityId: 0
    })
  }
  getActivityList(cityId, sortId, pageSize, pageNum, title='') {
    Ajax.POST(`/activity/list?cityId=${cityId}&sortId=${sortId}&title=${title}&pageNum=${pageNum}&pageSize=${pageSize}`).then(r => {
      this.setState({
        currentCityId: cityId
      })
      if (r.code === 200) {
        this.setState({
          activities: r.data.activityList.list,
          total: r.data.activityList.total
        })
      }
    })
  }
  searchActivity(title, cityId) {
    this.setState({
      activityTitle: title,
      cityId: cityId,
      pageNum: 1
    })
    this.getActivityList(cityId, this.state.activitySortId, this.state.pageSize, 1, title)
  }
  onChangePage(pageNum, pageSize) {
    this.setState({
      pageNum: pageNum
    })
    this.getActivityList(this.state.cityId, this.state.activitySortId, this.state.pageSize, pageNum, this.state.activityTitle)
  }
  render() {
    return (
      <div className="activity-list-container">
        <div className="search-activity-list">
          {this.state.activities.length > 0 && 
          <Row gutter={19}>
            {this.state.activities.map((item, index) => {
              return <Col span={6} key={index}>
                <div className="activity-item">
                  <div className="item-top">
                    <div className="activity-img">
                      <img src={item.activityImgUrl} alt="" className="carousel-cover"/>
                    </div>
                    <div className="image-overlay"></div>
                    <div className="activity-title">{item.activityTitle}</div>
                    <div className="activity-qrcode-btn">
                      <Button className="btn" type="link" onMouseOver={this.showActivityQRCode.bind(this, item.activityId)} onMouseOut={this.hideActivityQRCode.bind(this)}>
                        <img src={qrcodeIcon} alt="" />
                      </Button>
                    </div>
                    {this.state.isShowActivityQRCode && (item.activityId === this.state.showQRCodeActivityId) && 
                    <div className="activity-qrcode">
                      {this.state.qrcodeLoading && <Spin />}
                      {!this.state.qrcodeLoading && <img src={this.state.activityQRCodeImg} alt="" />}
                    </div>}
                    <div className="activity-site">
                      <Icon type="environment" />{item.activityAddress}
                    </div>
                    {/* <div className="activity-address">
                      <Icon type="environment" />{item.activityAddress}
                      { item.activityAddress === '线上' &&
                      <img src={onlineActivityIcon} alt="" />}
                      {item.activityAddress === '线下' &&
                      <img src={offlineActivtyIcon} alt="" />}
                    </div> */}
                  </div>
                  <div className="item-bottom">
                    <div className="taker-avatar">
                      <img src={item.userAvatarUrl} alt="" />
                    </div>
                    <div className="item-bottom-bd">
                      <div className="taker-nickname">{item.userNickname}</div>
                      <div className="activity-time">时间: {item.activityStartTime}</div>
                      <div className="acticity-max-people">名额: {item.activityMaxPeople}人
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            })}
          </Row>}
          {this.state.activities.length <= 0 && 
          <div className="activity-list-none">
            <div>
              <div className="none-icon"><Icon type="file-search"/></div>
              <div><span>暂无活动</span></div>
            </div>
          </div>}
        </div>
        {this.state.activities.length > 0 && 
        <div className="acticity-pagination">
          <Pagination current={this.state.pageNum} pageSize={this.state.pageSize} total={this.state.total} onChange={this.onChangePage.bind(this)}/>
        </div>}
      </div>
    )
  }
}