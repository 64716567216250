export default {
  status: {
    draft: '草稿',
    published: '已发布',
    reviewing: '审核中',
    rejected: '审核未通过',
    offline: '已下线',
    end: '已结束',
  }
}
