import React, { Component } from 'react'
import { Form, Input, Icon, Row, Col, Button } from 'antd'
import './index.less'
import { Ajax } from '../../util/ajax'
import FormItem from 'antd/lib/form/FormItem'
import eventBus from '../../eventBus'

class LoginForm extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }
  handleSubmit(e) {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        console.log('Received values of form: ', values);
        Ajax.POST('/login/user', {
          phone: values.phone,
          password: values.password
        }).then(r => {
          if (r.code === 200) {
            console.log(r)
            localStorage.setItem('phone', r.data.phone)
            localStorage.setItem('nickname', r.data.nickname)
            localStorage.setItem('userId', r.data.userId)
            localStorage.setItem('avatarUrl', r.data.avatarUrl)
            localStorage.setItem('isLogin', true)
            localStorage.setItem('userToken', r.data.token)
            localStorage.setItem('currentCityId', r.data.currentCityId)
            localStorage.setItem('currentCityName', r.data.currentCityName)
            localStorage.setItem('isAllowPublish', r.data.isAllowPublish)
            localStorage.setItem('commissionRate', r.data.commissionRate)
            if (r.data.userType) {
              localStorage.setItem('userType', r.data.userType)
            }
            eventBus.emit('userLogined')
          }
        })
      }
    });
  }

  componentDidMount() {
    eventBus.addListener('loginModalClosed', () => {
      this.props.form.resetFields()
    })
  }
  validatePhone(rule, value, callback) {
    console.log('phone',value)
    if (!(/^1[0-9]{10}$/.test(value))) {
      callback('请输入正确的手机号')
    } else {
      Ajax.POST(`/user/check/phone?phone=${value}`).then(r => {
        if (r.code === 200) {
          if (r.data === 'exist') {
            callback()
          } else {
            callback('该手机号未注册')
          }
        }
      })
    }
  }
  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <div id="login-form">
        <Form onSubmit={this.handleSubmit.bind(this)} className="login-form">
          <FormItem>
            {getFieldDecorator('phone', {
              rules: [{ required: true, message: '请输入您的手机号!' },
                { validator: this.validatePhone.bind(this)}]
            })(
              <Input
                prefix={<Icon type="phone" style={{ color: 'rgba(0,0,0,.25)' }} />}
                placeholder="输入11位手机号"
              />,
            )}
          </FormItem>
          <FormItem>
            {getFieldDecorator('password', {
              rules: [{ required: true, message: '请输入密码!' }]
            })(
              <Input
                prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                placeholder="输入密码"
                type="password"
              />
            )}
          </FormItem>
          <FormItem>
            <Button type="primary" htmlType="submit" className="login-form-button" block>
              登录
            </Button>
          </FormItem>
        </Form>
      </div>
    )
  }
}

const loginForm = Form.create()(LoginForm)

export default loginForm
