
export const getCookie = (key) => {
    var str = document.cookie.replace(/;\s*/, ';');
    var cookieArr = str.split(';');
    var cookieObj = {};
    var len = cookieArr.length;
    for (var i = 0; i < len; i++) {
      var item = cookieArr[i];
      var k = item.split('=')[0];
      var v = item.split('=')[1];
      cookieObj[k] = v;
    }
    if (cookieObj[key]) {
      return decodeURIComponent(cookieObj[key]);
    } else {
      return false;
    }
  
  }
  
  export const setCookie = (key, value, time) => {//设置cookie
    var date = new Date();
    date.setDate(date.getDate() + time);
    document.cookie = `${key}=${value};expires=` + date;
  }
  
  export const removeCookie = (key) => {
    var exp = new Date();
    exp.setTime(exp.getTime() - 1);
    var cval = getCookie(key);
    if (cval != null) document.cookie = key + "=" + cval + ";expires=" + exp.toGMTString();
  }

  export const parseAmount = (value) => {
    return parseFloat(value).toFixed(2)
  }
