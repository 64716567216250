import React, {Component} from 'react'

export default class ActivityCopy extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }
  render() {
    return (
      <div id="my-activity-copy"></div>
    )
  }
}
