import React, { Component } from 'react'
import { Button, Table, Breadcrumb, Row, Col} from 'antd'
import { Ajax } from '../../util/ajax'
import { Link } from 'react-router-dom'
import activities from '../../filter/activities'
import './index.less'

export default class ActivityDetail extends Component {
  constructor(props){
    super(props)
    this.state = {
      ActObj : {},
      typeList: [],
      fieldList: [],
      columns: [
        {
          title: '选项',
          dataIndex: 'typeName'
        }, {
          title: '费用',
          dataIndex: 'typePrice'
        }, {
          title: '库存',
          dataIndex: 'typeInitStock'
        }, {
          title: '截止时间',
          dataIndex: 'typeDeadline'
        }, {
          title: '鸽子费',
          dataIndex: 'typeDoveFee',
          render: (text, record) => {
            return (
              <div>
                {record.typeDoveFee === 1 && <span>是</span>}
                {record.typeDoveFee === 0 && <span>否</span>}
              </div>
            )
          }
        }
      ]
    }
  }

  componentDidMount(){
    let state = this.props.location.state;
    if(state){
      Ajax.POST('/activity/get?activityId=' + state.activityId).then(r =>{
        if(r.code === 200){
          this.setState({
            ActObj: r.data,
            typeList: r.data.typeList,
            fieldList: r.data.fieldList || []
          })
        }
      })
    }
  }
  Back(){
    setTimeout(() => {
      this.props.history.go(-1)
    }, 1000)
  }
  renderModalItem() {
    let data = this.state.typeList
    if(data){
      this.state.typeList.map((item, index) => {
        data[index].key = index
      })
      return (
        <Table
          dataSource={data}
          columns={this.state.columns}
          pagination = {false}
          bordered
        ></Table>
      )
    } else {
      return <div>暂无规格</div>
    }
  }
  render() {
    const  ActObj  = this.state.ActObj
    return (
      <div className="activity-detail">
        <div className="activity-detail-container">
        <Breadcrumb className="activity-detail-breadcrumb">
          <Breadcrumb.Item>
            <Link to={{pathname: '/my/activity/manage'}}>我发起的活动</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item><span style={{ color: "#E35F4B" }}>活动详情</span></Breadcrumb.Item>
        </Breadcrumb>
        <div className="activity-edit-card">
        <Row gutter={20} key="0">
          <Col span={6} className="title">编号</Col>
          <Col span={17}>{ActObj.activityNumber}</Col>
        </Row>
        <Row gutter={20} key="1">
          <Col span={6} className="title">标题</Col>
          <Col span={17}>{ActObj.activityTitle}</Col>
        </Row>
        <Row gutter={20} key="2">
          <Col span={6} className="title">图片</Col>
          <Col span={17}>
            <div className="activity-img"><img className="actImg" src={ActObj.activityImgUrl} alt="" /></div>
          </Col>
        </Row>
        <Row gutter={20} key="3">
          <Col span={6} className="title">日期</Col>
          <Col span={17}>{ActObj.activityStartTime} 至 {ActObj.activityEndTime}</Col>
        </Row>
        <Row gutter={20} key="4">
          <Col span={6} className="title">地点</Col>
          <Col span={17}>
            <div>
              {ActObj.activitySiteSort === 0 && <span>线上</span>}
              {ActObj.activitySiteSort === 1 && <span>{ActObj.activityAddress}</span>}
            </div>
          </Col>
        </Row>
        <Row gutter={20} key="5">
          <Col span={6} className="title">活动规格</Col>
          <Col span={17}>
            <div className="activity-sku">
              {this.renderModalItem()}
            </div>
          </Col>
        </Row>
        <Row gutter={20} key="6">
          <Col span={6} className="title">希望报名者参与问卷填写</Col>
          <Col span={17} className="activity-apply-info">
            {this.state.fieldList.map((item, index) => {
              return <div key={index}>
                <div>
                  <span style={{ marginRight: 50 }}>{item.fieldName}</span>
                </div>
              </div>
            })}
          </Col>
        </Row>
        <Row gutter={20} key="7">
          <Col span={6} className="title">活动内容</Col>
          <Col span={17}>
            <div className="activity-description">
              <div dangerouslySetInnerHTML={{__html:ActObj.activityDescription}}></div>
            </div>
          </Col>
        </Row>
        <Row gutter={20} key="8">
          <Col span={6} className="title">活动状态</Col>
          <Col span={17}>{activities.status[ActObj.status]}</Col>
        </Row>
        <Row gutter={20} key="9">
          <Col span={6} className="title"></Col>
          <Col span={17}>
            <div className="return-btn"><Button type="primary" style={{ width: 78, height: 36 }} onClick={this.Back.bind(this)}>返回 </Button></div>
          </Col>
        </Row>
        </div>
        </div>
      </div>
    )
  }
}
